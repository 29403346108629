import { Component, Mixins } from "vue-property-decorator";

import config from "@/config";
import Panel from "@/components/Containers/Panel";
import RadioGroupEnums from "./radioGroupEnums";
import ParameterPanelHeader from "./ParameterPanelHeader";
import ParameterTemplateMixin from "./ParameterTemplateMixin";

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class NmeaModeConfigurationTemplate extends Mixins(ParameterTemplateMixin) {
    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    header="Настройка режима NMEA"
                    parameter={this.parameter}
                />
                <hr />
                <div class="d-flex flex-column">
                    <div class="w-100 text-left mt-3">Режим NMEA</div>
                    <el-radio-group vModel={this.value.NMEAMode} class="w-100 d-flex flex-column align-items-start">
                        {RadioGroupEnums.nmeaMode.map(enumValue => (
                            <el-radio class="mx-0 my-1" key={enumValue.value} label={enumValue.value}>
                                {this.$t(`enums.nmeaMode.${enumValue.key}`)}
                            </el-radio>
                        ))}
                    </el-radio-group>
                </div>
            </panel>
        );
    }
}
