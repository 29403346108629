//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconBase from "@/components/Icons/IconBase.vue";

export default {
    name: "ImagePicker",
    components: {
        IconBase
    },
    props: {
        items: Array,
        value: String,
        disabled: {
            default: false
        },
        backgroundColor: String
    },
    computed: {
        pickerDisabled() {
            return this.disabled || (this.elForm || {}).disabled;
        }
    },
    methods: {
        handleChange(value) {
            if (!this.pickerDisabled) {
                this.$emit("input", value);
            }
        }
    },
    inject: {
        elForm: {
            default: ""
        }
    }
};
