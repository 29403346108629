import cloneDeep from "lodash/cloneDeep";

import { notificationTypes } from "../const/notification-enums";
import regexHolder from "@/const/string-validation-regex";
import { Component, Vue, Prop } from "vue-property-decorator";
import { NotificationType, NotificationMode, INotificationSettings } from "./INotificationSettingsForm";
import IBeaconManagement from "@/contracts/IBeaconManagement";
import { IChannelInfo, ChannelType } from "@/contracts/IChannelInfo";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import IGeoobject from "@/contracts/IGeoobject";

@Component
export default class NotificationEditor extends Vue {
    @Prop()
    notificationSettings!: INotificationSettings;

    @Prop()
    beacon!: IBeaconManagement;

    @Prop()
    channels!: IChannelInfo[];

    @Prop()
    geoobjects!: IGeoobject[];

    @State
    user!: IUser;

    private emails: { email: string }[] = [];
    private form: INotificationSettings | null = null;

    get channel() {
        if (this.channels && this.form && this.form.ChannelId) {
            return this.channels.find(el => el.Id === (this.form && this.form.ChannelId));
        }
        return null;
    }

    get channelNotificationType() {
        return (
            this.form &&
            this.channels &&
            (this.form.NotificationType === NotificationType.RealChannelState ||
                this.form.NotificationType === NotificationType.RealChannelStateWithCustomThresholds ||
                this.form.NotificationType === NotificationType.BinaryChannelState ||
                this.form.NotificationType === NotificationType.EnumChannelState)
        );
    }

    async handleSave() {
        try {
            if (this.form) {
                await (this.$refs.form as any).validate();
                this.form.Emails = this.emails.map(el => el.email).filter(Boolean);
                this.$emit("save", this.notificationSettings.Id, cloneDeep(this.form));
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleEmailInput(value) {
        if (this.emails[this.emails.length - 1].email && this.emails.length < 5) {
            this.emails.push({ email: "" });
        }
        if (!this.emails[this.emails.length - 1].email && !this.emails[this.emails.length - 2].email) {
            this.emails.pop();
        }
    }

    handleDeleteEmail(index: number) {
        this.emails.splice(index, 1);
    }

    rules() {
        return {
            Name: [
                {
                    required: true,
                    message: this.$t("default.required"),
                    trigger: "blur"
                },
                {
                    max: 250,
                    message: this.$t("default.maxlength", { length: 250 }),
                    trigger: "blur"
                },
                {
                    pattern: regexHolder["settings_name"].value,
                    message: this.$t("default.invalid"),
                    trigger: "blur"
                }
            ],
            email: [
                {
                    max: 50,
                    // message: this.$t('default.maxlength', { length: 50 }),
                    message: "",
                    trigger: "blur"
                }
            ]
        };
    }

    created() {
        this.emails =
            this.notificationSettings && this.notificationSettings.Emails
                ? this.notificationSettings.Emails.map(email => ({
                      email: email
                  }))
                : [{ email: this.user.Email }];

        this.emails.push({ email: "" });

        this.form = cloneDeep<INotificationSettings>(this.notificationSettings);

        if (!~this.notificationSettings.Id) {
            this.form.Settings = {
                ...notificationTypes[this.form.NotificationType].settings
            };
            this.form.StatusType = notificationTypes[this.form.NotificationType].statusType;
        }
    }

    get notificationModes() {
        if (!this.form) {
            return 2;
        }
        let email = this.form.NotificationModes.includes(NotificationMode.Email);
        let app = this.form.NotificationModes.includes(NotificationMode.App);
        return email && app ? 2 : email ? 1 : app ? 0 : 2;
    }

    set notificationModes(value) {
        if (this.form) {
            switch (value) {
                case 0:
                    this.form.NotificationModes = [NotificationMode.App];
                    break;
                case 1:
                    this.form.NotificationModes = [NotificationMode.Email];
                    break;
                case 2:
                    this.form.NotificationModes = [NotificationMode.App, NotificationMode.Email];
                    break;
            }
        }
    }

    get useChannelThresholds() {
        return this.form && this.form.NotificationType === NotificationType.RealChannelState;
    }

    set useChannelThresholds(value) {
        const channel = this.channels.find(el => el.Id === (this.form && this.form.ChannelId));
        if (this.form && channel && channel.ChannelType === ChannelType.Real) {
            if (value) {
                this.form.NotificationType = NotificationType.RealChannelState;
            } else {
                this.form.NotificationType = NotificationType.RealChannelStateWithCustomThresholds;
            }

            this.form.Settings = {
                ...notificationTypes[this.form.NotificationType].settings
            };

            if (this.form.NotificationType === NotificationType.RealChannelStateWithCustomThresholds) {
                this.form.Settings.MinThreshold = channel.Min;
                this.form.Settings.MaxThreshold = channel.Max;
            }
        }
    }

    onNotificationModeChanged() {
        if (this.form) {
            if (this.form.NotificationModes.includes(NotificationMode.Email)) {
                this.emails = [{ email: this.user.Email }, { email: "" }];
            } else {
                this.emails = [];
            }
        }
    }

    onChannelIdChanged(value: number) {
        const channel = this.channels.find(el => el.Id === value);
        if (channel && this.form) {
            if (channel.ChannelType === ChannelType.Real) {
                this.form.NotificationType = NotificationType.RealChannelState;
            } else if (channel.ChannelType === ChannelType.Binary) {
                this.form.NotificationType = NotificationType.BinaryChannelState;
            }

            this.useChannelThresholds = true;

            this.form.Settings = {
                ...notificationTypes[this.form.NotificationType].settings
            };
        }
    }

    render() {
        if (!this.form || !this.emails) {
            return;
        }

        return (
            <div>
                <el-form {...{ attrs: { model: this.form } }} rules={this.rules()} ref="form" size="small">
                    <div class="d-flex flex-column">
                        <div class="w-100 mr-4">
                            {this.form.NotificationType === NotificationType.BeaconLocationInGeoobject &&
                            this.geoobjects ? (
                                <div>
                                    <el-form-item
                                        label={this.$t("notificationeditor.geozones")}
                                        class="mb-2"
                                        prop="GeoobjectId"
                                    >
                                        <el-select vModel={this.form.GeoobjectId}>
                                            {this.geoobjects.map(geoobject => (
                                                <el-option
                                                    key={geoobject.Id}
                                                    label={geoobject.Name}
                                                    value={geoobject.Id}
                                                />
                                            ))}
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item class="mb-2">
                                        <div class="d-flex flex-column">
                                            <div class="font-weight-medium w-100">
                                                {this.$t("notificationeditor.notifywhen")}
                                            </div>
                                            <el-checkbox-group
                                                min={1}
                                                class="d-flex flex-column"
                                                vModel={this.form.Settings.Trigger}
                                            >
                                                <el-checkbox label={1}>
                                                    {this.$t("notificationeditor.ingeozone")}
                                                </el-checkbox>
                                                <el-checkbox label={2}>
                                                    {this.$t("notificationeditor.outofgeozone")}
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                    </el-form-item>
                                </div>
                            ) : null}
                            {this.form.NotificationType === NotificationType.LastOnline ? (
                                <el-form-item class="mb-2">
                                    <div class="d-flex flex-column">
                                        <div class="w-100 font-weight-medium">
                                            {this.$t("notificationeditor.lastonline")}
                                        </div>
                                        <el-input-number
                                            vModel={this.form.Settings.MinutesTimeout}
                                            controls-position="right"
                                            min={1}
                                            max={1440}
                                        ></el-input-number>
                                    </div>
                                </el-form-item>
                            ) : null}
                            {this.form.NotificationType === NotificationType.BeaconCommandState ? (
                                <el-form-item class="mb-2">
                                    <div class="d-flex flex-column">
                                        <div class="w-100 font-weight-medium">
                                            {this.$t("notificationeditor.notifywhen")}
                                        </div>
                                        <el-checkbox-group
                                            min={1}
                                            class="d-flex flex-column"
                                            vModel={this.form.Settings.Trigger}
                                        >
                                            <el-checkbox label={1}>
                                                {this.$t("notificationeditor.sendingcommand")}
                                            </el-checkbox>
                                            <el-checkbox label={2}>
                                                {this.$t("notificationeditor.confirmcommand")}
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                </el-form-item>
                            ) : null}
                            {this.channelNotificationType ? (
                                <div>
                                    <el-form-item
                                        label={this.$t("notificationeditor.sensors")}
                                        class="mb-2"
                                        prop="ChannelId"
                                    >
                                        <el-select onChange={this.onChannelIdChanged} vModel={this.form.ChannelId}>
                                            {this.channels.map(channel => (
                                                <el-option key={channel.Id} label={channel.Name} value={channel.Id} />
                                            ))}
                                        </el-select>
                                    </el-form-item>
                                    {this.channel && this.channel.ChannelType === ChannelType.Real ? (
                                        <div>
                                            <el-checkbox vModel={this.useChannelThresholds}>
                                                {this.$t("notificationeditor.usechannelthresholds")}
                                            </el-checkbox>
                                            {this.form.NotificationType === NotificationType.RealChannelState ? (
                                                <el-form-item>
                                                    <div class="font-weight-medium w-100">
                                                        {this.$t("notificationeditor.notifywhen")}
                                                    </div>
                                                    <el-checkbox-group min={1} vModel={this.form.Settings.Trigger}>
                                                        <el-checkbox label={0}>
                                                            {this.$t("notificationeditor.normalstate")}
                                                        </el-checkbox>
                                                        <el-checkbox label={1}>
                                                            {this.$t("notificationeditor.preemergencystate")}
                                                        </el-checkbox>
                                                        <el-checkbox label={2}>
                                                            {this.$t("notificationeditor.emergencystate")}
                                                        </el-checkbox>
                                                        <el-checkbox label={3}>
                                                            {this.$t("notificationeditor.incorrectstate")}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-form-item>
                                            ) : this.form.NotificationType ===
                                              NotificationType.RealChannelStateWithCustomThresholds ? (
                                                <div>
                                                    <div class="font-weight-medium w-100">
                                                        {this.$t("notificationeditor.interval")}
                                                    </div>
                                                    <div class="d-flex">
                                                        <el-form-item prop="Settings.MinThreshold" style="flex: 1;">
                                                            <el-input-number
                                                                max={this.form.Settings.MaxThreshold}
                                                                min={this.channel.Min}
                                                                class="w-100"
                                                                vModel={this.form.Settings.MinThreshold}
                                                            />
                                                        </el-form-item>
                                                        <span class="mx-4">-</span>
                                                        <el-form-item prop="Settings.MaxThreshold" style="flex: 1">
                                                            <el-input-number
                                                                max={this.channel.Max}
                                                                min={this.form.Settings.MinThreshold}
                                                                class="w-100"
                                                                vModel={this.form.Settings.MaxThreshold}
                                                            />
                                                        </el-form-item>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : this.channel && this.channel.ChannelType === ChannelType.Binary ? (
                                        <el-form-item class="mb-2">
                                            <div style="width: 100%;">{this.$t("notificationeditor.notifywhen")}</div>
                                            <el-radio-group
                                                class="d-flex flex-column"
                                                vModel={this.form.Settings.Trigger}
                                            >
                                                <el-radio label={0}>{this.$t("notificationeditor.zerotoone")}</el-radio>
                                                <el-radio label={1}>{this.$t("notificationeditor.onetozero")}</el-radio>
                                                <el-radio label={2}>
                                                    {this.$t("notificationeditor.zerotooneandonetozero")}
                                                </el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                        <div class="w-100">
                            <el-form-item class="mb-2">
                                <div class="font-weight-medium w-100">{this.$t("notificationeditor.notify")}</div>
                                <el-radio-group
                                    class="d-flex flex-column"
                                    onChange={this.onNotificationModeChanged}
                                    vModel={this.notificationModes}
                                >
                                    <el-radio label={0}>{this.$t("notificationeditor.app")}</el-radio>
                                    <el-radio label={1}>{this.$t("notificationeditor.email")}</el-radio>
                                    <el-radio label={2}>{this.$t("notificationeditor.appandemail")}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            {this.form.NotificationModes.includes(NotificationMode.Email) ? (
                                <div class="d-flex flex-column mb-2">
                                    <label class="font-weight-medium">{this.$t("notificationeditor.emails")}</label>
                                    {this.emails.map((email, index) => (
                                        <div key={index} class="d-flex justify-content-center align-items-center">
                                            <el-form-item class="mr-3 mb-0" prop="email" style="flex: 1">
                                                <el-input
                                                    maxlength={50}
                                                    onInput={this.handleEmailInput}
                                                    type="email"
                                                    vModel={email.email}
                                                />
                                            </el-form-item>
                                            <el-button
                                                disabled={index === this.emails.length - 1}
                                                onClick={() => this.handleDeleteEmail(index)}
                                                icon="el-icon-delete"
                                                type="text"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </el-form>
                <div class="d-flex justify-content-end mt-4">
                    <el-button size="medium" onClick={() => this.$emit("close")}>
                        {this.$t("default.cancel")}
                    </el-button>
                    <el-button size="medium" onClick={this.handleSave} type="primary">
                        {this.$t("default.save")}
                    </el-button>
                </div>
            </div>
        );
    }
}
