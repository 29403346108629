//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ColorPicker",
    props: {
        value: String,
        items: Array,
        disabled: {
            default: false
        }
    },
    inject: {
        elForm: {
            default: ""
        }
    },
    computed: {
        pickerDisabled() {
            return this.disabled || (this.elForm || {}).disabled;
        }
    },
    methods: {
        handleChange(item) {
            if (!this.pickerDisabled) {
                this.$emit("input", item);
            }
        }
    }
};
