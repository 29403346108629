export default [
    "#7f899b",
    "#0b52bc",
    "#1d0bbc",
    "#750bbd",
    "#bd0b53",
    "#bd760b",
    "#acbe0b",
    "#53be0b",
    "#0bbe1d",
    "#0bbe76",
    "#0bacbe",

    "#374047",
    "#00365d",
    "#27005e",
    "#580061",
    "#610038",
    "#5e2700",
    "#687307",
    "#386100",
    "#086100",
    "#006128",
    "#006159"

    // "#BF2600",
    // "#FF8B00",
    // "#006644",
    // "#008DA6",
    // "#403294",
    // "#0747A6",
    // "#091E42",

    // "#DE350B",
    // "#FF991F",
    // "#00875A",
    // "#00A3BF",
    // "#5243AA",
    // // "#0052CC",
    // "#0b52bc",
    // "#253858",

    // "#FF5630",
    // "#FFAB00",
    // "#36B37E",
    // "#00B8D9",
    // "#8777D9",
    // "#0065FF",
    // "#42526E"
];
