import regexHolder from "@/const/string-validation-regex";

import AsyncValidator from "async-validator";
import { Component, Prop, Vue } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class TransportCardFields extends Vue {
    @Prop()
    transportCard;

    form: any = cloneDeep(this.transportCard);

    save() {
        try {
            let descriptor = {
                label: [
                    {
                        validator(rule, value, callback, source, options) {
                            let errors: any = [];
                            for (let i = 0; i < value.length - 1; i++) {
                                let field = value[i];
                                if (!regexHolder["transport_card_fields"].value.test(field)) {
                                    errors.push("Label - " + field + " is invalid");
                                }
                            }
                            callback(errors);
                        }
                    }
                ]
            };
            var validator = new AsyncValidator(descriptor);
            let fields = this.form.map(value => {
                return value.label;
            });
            validator.validate({ label: fields }, (errors, fields) => {
                if (errors) {
                    this.$message({
                        message: this.$t("default.invalid").toString(),
                        showClose: true,
                        offset: 70,
                        iconClass: "fad fa-exclamation-circle fa-lg mr-3 color-white",
                        customClass: "el-message--error"
                    });
                    throw errors;
                } else {
                    this.form.pop();
                    this.$emit("save", this.form);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    cancel() {
        for (let i = 0; i < this.form.length - 1; i++) {
            this.form[i].label = this.form[i].label.replace(regexHolder["negative_transport_card_fields"].value, "");
        }
        this.$emit("close");
    }

    handleInput(value) {
        if (this.form[this.form.length - 1].label && this.form.length < 20) {
            this.form.push({ label: "", value: "" });
        }
        if (this.form[this.form.length - 1].label === "" && this.form[this.form.length - 2].label === "") {
            this.form.pop();
        }
    }

    handleDelete(index) {
        this.form.splice(index, 1);
    }

    mounted() {
        if (this.form.length < 1) {
            this.form.push({ label: "", value: "" });
        } else {
            if (this.form[this.form.length - 1].label) {
                this.form.push({ label: "", value: "" });
            }
        }
    }

    render() {
        return (
            <div>
                <el-form size="small">
                    {this.form.map((item, index) => (
                        <div class="d-flex" key={index}>
                            <el-form-item style="flex: 1;">
                                <el-input maxlength={100} vModel={item.label} onInput={this.handleInput} />
                            </el-form-item>
                            <el-form-item class="ml-3">
                                <el-button
                                    disabled={index === this.form.length - 1}
                                    icon="el-icon-delete"
                                    type="text"
                                    onClick={() => this.handleDelete(index)}
                                />
                            </el-form-item>
                        </div>
                    ))}

                    <el-form-item class="d-flex justify-content-end">
                        <el-button size="small" onClick={this.cancel}>
                            {this.$t("default.cancel")}
                        </el-button>
                        <el-button onClick={this.save} size="small" type="primary">
                            {this.$t("default.apply")}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        );
    }
}
