import managementAPI, { IBeaconFD } from "@/api/management";
import { findBeacons } from "@/search";

import IconBase from "@/components/Icons/IconBase.vue";
import Panel from "@/components/Containers/Panel";
import { Component, Vue } from "vue-property-decorator";

import "./ExternalIntegrations.scss";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import IBeaconManagement from "@/contracts/IBeaconManagement";
import FiskeriIntegrationModal from "./FiskeriIntegrationModal";

@Component({
    components: {
        IconBase,
        Panel,
        FiskeriIntegrationModal
    }
})
export default class ExternalIntegrations extends Vue {
    private modal: boolean = false;
    private integrations: IBeaconFD[] = [];
    private beacons: IBeaconManagement[] = [];

    @State
    user!: IUser;

    public activated() {
        this.modal = false;
        this.loadData();
    }

    get filteredBeacons() {
        return this.beacons.filter(x => !this.integrations.some(y => y.BeaconId === x.Id));
    }

    private async loadData() {
        this.integrations = await managementAPI.getFDIntegrations();
        this.beacons = await managementAPI.loadBeacons();
    }

    public created() {
        this.loadData();
    }

    public async handleDelete(id: number) {
        await this.$confirm(this.$t("default.thisActionCannotBeUndoneDelete").toString(), "", {
            confirmButtonText: this.$t("default.ok").toString(),
            cancelButtonText: this.$t("default.cancel").toString(),
            type: "warning",
            center: true,
            showClose: false
        });
        await managementAPI.deleteFDIntegration(id);
        this.loadData();
    }

    public async handleToggle(id: number) {
        await managementAPI.toggleFDIntegration(id);
        this.loadData();
    }

    public async handleCommand(id: number, command: string) {
        if (this.user.FdIntegration) {
            switch (command) {
                case "delete":
                    await this.handleDelete(id);
                    break;
                case "toggle":
                    await this.handleToggle(id);
                    break;
            }
        }
    }

    public addedIntegration() {
        this.modal = false;
        this.loadData();
    }

    public render() {
        return (
            <div class="beacons-integrations scroll">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="text-left col-xl-6 col-md-12">
                            <panel>
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-8 col-sm-8 col-12 d-flex align-items-center mb-3">
                                            <h4 class="page-header mb-0">{this.$t("integrations.fd")}</h4>
                                        </div>
                                        <div class="d-flex justify-content-end col-md-4 col-sm-4 col-12 mb-3">
                                            {this.modal && (
                                                <fiskeri-integration-modal
                                                    beacons={this.filteredBeacons}
                                                    onAdded={this.addedIntegration}
                                                    onClose={() => {
                                                        this.modal = false;
                                                    }}
                                                    visible={this.modal}
                                                ></fiskeri-integration-modal>
                                            )}
                                            <el-button
                                                size="small"
                                                type="primary"
                                                onClick={() => {
                                                    this.modal = true;
                                                }}
                                            >
                                                {this.$t("default.add")}
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                                <el-table
                                    fit
                                    data={this.integrations}
                                    show-header={true}
                                    empty-text={this.$t("default.nodata")}
                                >
                                    <el-table-column
                                        sortable
                                        show-overflow-tooltip
                                        min-width="200"
                                        label={this.$t("default.name")}
                                        prop="Name"
                                    />
                                    <el-table-column
                                        sortable
                                        show-overflow-tooltip
                                        min-width="160"
                                        label="Iridium IMEI"
                                        prop="IridiumImei"
                                    />
                                    <el-table-column
                                        sortable
                                        show-overflow-tooltip
                                        min-width="80"
                                        label="Radio Call Sign"
                                        prop="DeviceId"
                                    />
                                    <el-table-column
                                        min-width="90"
                                        align="left"
                                        label="Sporing status"
                                        {...{
                                            scopedSlots: {
                                                default: (scope: { row: IBeaconFD }) => {
                                                    return scope.row.Disabled ? (
                                                        <el-tag type="info">Disabled</el-tag>
                                                    ) : (
                                                        <el-tag type="success">Enabled</el-tag>
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                    <el-table-column
                                        label={this.$t("default.actions")}
                                        align="right"
                                        {...{
                                            scopedSlots: {
                                                default: (scope: { row: IBeaconFD }) => {
                                                    return (
                                                        <div class="d-flex align-items-center justify-content-end">
                                                            <div class="d-flex align-items-center justify-content-center">
                                                                <el-dropdown
                                                                    size="small"
                                                                    trigger="click"
                                                                    onCommand={command =>
                                                                        this.handleCommand(scope.row.Id, command)
                                                                    }
                                                                >
                                                                    <el-button
                                                                        size="small"
                                                                        icon="fas fa-ellipsis-v fa-lg"
                                                                    />
                                                                    <el-dropdown-menu slot="dropdown">
                                                                        <el-dropdown-item
                                                                            class="mini-beacon-card__command"
                                                                            command="toggle"
                                                                        >
                                                                            {scope.row.Disabled
                                                                                ? this.$t("integrations.fdEnable")
                                                                                : this.$t("integrations.fdDisable")}
                                                                        </el-dropdown-item>
                                                                        <el-dropdown-item
                                                                            class="mini-beacon-card__command"
                                                                            command="delete"
                                                                        >
                                                                            {this.$t("default.delete")}
                                                                        </el-dropdown-item>
                                                                    </el-dropdown-menu>
                                                                </el-dropdown>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                </el-table>
                            </panel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
