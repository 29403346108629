import { Vue, Component } from "vue-property-decorator";

import "./ManagementView.scss";

@Component
export default class ManagementView extends Vue {
    render() {
        return (
            <div class="d-flex canvas">
                <transition name="fade" mode="out-in">
                    <router-view class="page-view d-flex flex-column w-100" />
                </transition>
            </div>
        );
    }
}
