import { Component, Vue, Prop } from "vue-property-decorator";
import IBeaconManagement from "@/contracts/IBeaconManagement";

@Component
export default class BeaconCard extends Vue {
    @Prop()
    beacon!: IBeaconManagement;

    @Prop()
    selected!: boolean;

    public handleClick() {
        this.$emit("select", this.beacon.Id);
    }

    public render() {
        return (
            <div onClick={this.handleClick} class={["lo-card p-3 mb-3", this.selected && "selected"]}>
                <div class="lo-card_title mb-3">{this.$t(`parameters.${this.beacon.Name}`)}</div>
                <div class="lo-card_description d-flex flex-column">
                    <div class="mb-1 d-flex font-size-xs">
                        <div class="text-muted mr-1">Iridium IMEI: </div>
                        <div class="font-weight-medium">{this.beacon.IridiumImei}</div>
                    </div>
                    <div class="mb-1 d-flex font-size-xs">
                        <div class="text-muted mr-1"> GSM Imei: </div>
                        <div class="font-weight-medium">{this.beacon.GsmImei}</div>
                    </div>
                    <div class="mb-1 d-flex font-size-xs">
                        <div class="text-muted mr-1"> Serial: </div>
                        <div class="font-weight-medium">{this.beacon.SerialNumber}</div>
                    </div>
                </div>
            </div>
        );
    }
}
