import managementAPI from "@/api/management";
import { Component, Prop, Vue } from "vue-property-decorator";
import BeaconCard from "./BeaconCard";
import IBeaconManagement from "@/contracts/IBeaconManagement";
import "./FiskeriIntegrationModal.scss";

interface IForm {
    SelectedBeaconId: number | null;
    SelectedBeaconRC: string;
}

@Component({
    components: {
        BeaconCard
    }
})
export default class FiskeriIntegrationModal extends Vue {
    @Prop()
    visible!: boolean;

    @Prop()
    beacons!: IBeaconManagement[];

    private form: IForm = {
        SelectedBeaconId: null,
        SelectedBeaconRC: ""
    };

    private async handleAddBeaconIntegration() {
        if (this.form.SelectedBeaconId && this.form.SelectedBeaconRC) {
            await managementAPI.createFDIntegration(this.form.SelectedBeaconId, this.form.SelectedBeaconRC);
            this.$emit("added");
        }
    }

    public render() {
        return (
            <el-dialog
                class="fiskeri-integration-modal"
                modal-append-to-body
                append-to-body
                close-on-click-modal={false}
                title="Add beacon FD integration"
                visible={this.visible}
                onClose={() => this.$emit("close")}
                width="600px"
            >
                <div class="p-3 flex-grow-1">
                    <el-form {...{ attrs: { model: this.form } }} show-message={false} ref="form" size="small">
                        <el-form-item
                            label={this.$t("default.beacon")}
                            prop="SelectedBeaconId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                    trigger: "change"
                                }
                            ]}
                        >
                            <el-select vModel={this.form.SelectedBeaconId} filterable>
                                {this.beacons.map(beacon => (
                                    <el-option key={beacon.Id} label={beacon.Name} value={beacon.Id}>
                                        <div class="d-flex align-items-center">
                                            <div
                                                style={{
                                                    backgroundColor: `${beacon.UserInterfaceSettings.Color}22`
                                                }}
                                                class="rounded-circle mr-2"
                                            >
                                                <icon-base
                                                    iconHeight={24}
                                                    iconWidth={24}
                                                    iconName={beacon.IconPath}
                                                    iconColor={beacon.UserInterfaceSettings.Color}
                                                />
                                            </div>
                                            <div>{beacon.Name}</div>
                                        </div>
                                    </el-option>
                                ))}
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="RC"
                            prop="SelectedBeaconRC"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                    trigger: "blur"
                                }
                            ]}
                        >
                            <el-input vModel={this.form.SelectedBeaconRC} maxlength={100} />
                        </el-form-item>
                    </el-form>
                </div>
                <div class="fiskeri-integration-modal__footer">
                    <el-button
                        onClick={this.handleAddBeaconIntegration}
                        size="small"
                        type="primary"
                        disabled={!this.form.SelectedBeaconId || !this.form.SelectedBeaconRC}
                    >
                        {this.$t("default.add")}
                    </el-button>
                </div>
            </el-dialog>
        );
    }
}
