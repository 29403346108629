import Vue from "vue";

export default Vue.extend({
    methods: {
        operationSuccess() {
            this.$message({
                showClose: true,
                message: this.$t("default.operationsuccess").toString(),
                offset: 70,
                iconClass: "fad fa-check-circle fa-lg mr-3 color-white",
                customClass: "el-message--success"
            });
        },
        operationFailed() {
            this.$message({
                showClose: true,
                message: this.$t("default.operationfailed").toString(),
                offset: 70,
                iconClass: "fad fa-exclamation-circle fa-lg mr-3 color-white",
                customClass: "el-message--error"
            });
        }
    }
});
