import http from "@/api/http";
import store from "@/store";
import * as types from "@/store/const/mutation-types";
import handleError from "@/api/handleError";

import IActivationInfo from "@/contracts/IActivationInfo";
import IChannelSettings from "@/contracts/IChannelSettings";
import IBeaconManagement, { CommunicationType } from "@/contracts/IBeaconManagement";
import { INotificationSettings } from "@/components/Management/Beacons/Notifications/INotificationSettingsForm";
import IParameter from "@/contracts/IParameter";

export interface IParameterSpecification {
    Protocol: string;
    Parameter: string;
    Key: string;
}

export interface IParameterCreateModel {
    Id: number;
    AccessLevel: number;
    Specification: IParameterSpecification;
    ClientSpecification: string;
}

export interface IBeaconFD {
    Id: number;
    Name: string;
    IridiumImei: string;
    GsmImei: string;
    SerialNumber: string;
    BeaconId: number;
    DeviceId: string;
    Disabled: boolean;
}

export default {
    async activateBeacon(activationInfo: IActivationInfo): Promise<any> {
        const { data } = await http.post("/beacons.activate", activationInfo);
        return data;
    },
    async loadBeacons(): Promise<any> {
        try {
            const { data } = await http.get("/beacons.getInfo/");
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadBeacon(id: number): Promise<IBeaconManagement> {
        try {
            const { data } = await http.get<IBeaconManagement>(`/beacons.getInfo/${id}`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async loadNotificationsSettings(beaconId: number): Promise<INotificationSettings[]> {
        try {
            const { data } = await http.get<INotificationSettings[]>(`/beacons/${beaconId}/notificationSettings/`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            return [];
        }
    },
    async createNotificationSettings(
        beaconId: number,
        settings: INotificationSettings
    ): Promise<INotificationSettings> {
        try {
            const { data } = await http.post(`/beacons/${beaconId}/notificationSettings/`, settings);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async updateNotificationSettings(
        beaconId: number,
        id: number,
        settings: INotificationSettings
    ): Promise<INotificationSettings> {
        try {
            const { data } = await http.put(`/beacons/${beaconId}/notificationSettings/${id}`, settings);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async deleteNotificationSettings(beaconId: number, id: number): Promise<void> {
        try {
            await http.delete(`/beacons/${beaconId}/notificationSettings/${id}`);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadParameters(beaconId: number): Promise<IParameter[]> {
        try {
            const { data } = await http.get(`/beacons/${beaconId}/parameters`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async updateBeaconParameter(beaconId: number, parameterId: number, value: string): Promise<IParameter> {
        const { data } = await http({
            method: "put",
            url: `/beacons/${beaconId}/parameters/${parameterId}`,
            params: {
                value
            }
        });
        return data;
    },
    async loadChannels(beaconId: number): Promise<any> {
        try {
            const { data } = await http.get(`/beacons/${beaconId}/channels.getInfo/`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadGeoobjects(): Promise<any> {
        try {
            const { data } = await http.get("/geoobjects/");
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadBeaconCard(beaconId: number): Promise<any> {
        try {
            const { data } = await http.get(`/beacons.getCard/${beaconId}`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async updateBeaconCard(beaconId: number, card: any): Promise<any> {
        try {
            const { data } = await http.put(`/beacons.editCard/${beaconId}`, card);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadBeaconSettings(beaconId: number): Promise<any> {
        try {
            const { data } = await http.get(`/beacons.getSettings/${beaconId}`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async updateBeaconSettings(beaconId: number, data: any): Promise<any> {
        try {
            await http.put(`/beacons.editSettings/${beaconId}`, data);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async updateCommunicationType(beaconId: number, value: CommunicationType): Promise<any> {
        try {
            await http.put(`/beacons.editCommunicationType/${beaconId}`, {
                Id: beaconId,
                PreferredCommunicationType: value
            });
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadChannelSettings(beaconId: number, channelId: number): Promise<IChannelSettings | undefined> {
        try {
            const { data } = await http.get(`/beacons/${beaconId}/channels.getSettings/${channelId}`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadTanksSettings(beaconId: number, channelId: number): Promise<any> {
        try {
            const { data } = await http.get(`/beacons/${beaconId}/channels.getTankSettings/${channelId}`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async updateTanksSettings(beaconId: number, channelId: number, data: any): Promise<any> {
        try {
            await http.put(`/beacons/${beaconId}/channels.editTankSettings/${channelId}`, data);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async updateChannelSettings(beaconId: number, channelId: number, data: any): Promise<any> {
        try {
            await http.put(`/beacons/${beaconId}/channels.editSettings/${channelId}`, data);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
        }
    },
    async loadHostSuggestions() {
        try {
            const { data } = await http.get<string[]>(`/beacons.getHostAutocomplete`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async loadPortSuggestions() {
        try {
            const { data } = await http.get<string[]>(`/beacons.getPortAutocomplete`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async loadSpecificationSuggestions() {
        try {
            const { data } = await http.get<string[]>(`/beacons.getSpecificationAutocomplete`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async loadParameterCreateModels(beaconId: number) {
        try {
            const { data } = await http.get<IParameterCreateModel[]>(`/beacons/${beaconId}/parameters.getAll`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async createBeaconParameter(beaconId: number, id: number) {
        try {
            await http.post(`/beacons/${beaconId}/parameters/${id}`);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async deleteBeaconParameter(beaconId: number, beaconParameterId: number) {
        try {
            await http.delete(`/beacons/${beaconId}/parameters/${beaconParameterId}`);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async getFDIntegrations() {
        try {
            const { data } = await http.get<IBeaconFD[]>(`/beacons.getFDIntegrations`);
            return data;
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async deleteFDIntegration(id: number) {
        try {
            await http.delete(`/beacons.deleteFDIntegration/${id}`);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async createFDIntegration(id: number, rc: string) {
        try {
            await http.post(`/beacons.createFDIntegration`, {
                Id: id,
                RadioCall: rc
            });
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    },
    async toggleFDIntegration(id: number) {
        try {
            await http.get(`/beacons.toggleFDIntegration/${id}`);
        } catch (error) {
            store.commit(types.ERROR, handleError(error));
            throw error;
        }
    }
};
