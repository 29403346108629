import { Component, Vue, Prop } from "vue-property-decorator";
import { IParameterCreateModel } from "@/api/management";

@Component
export default class ParameterCard extends Vue {
    @Prop()
    parameter!: IParameterCreateModel;

    @Prop()
    selected!: boolean;

    public handleClick() {
        this.$emit("select", this.parameter.Id);
    }

    public render() {
        return (
            <div onClick={this.handleClick} class={["lo-card p-3 mb-3", this.selected && "selected"]}>
                <div class="lo-card_title mb-3">{this.$t(`parameters.${this.parameter.ClientSpecification}`)}</div>
                <div class="lo-card_description d-flex flex-column">
                    <div class="mb-1 d-flex font-size-xs">
                        <div class="text-muted mr-1">Протокол: </div>
                        <div class="font-weight-medium">{this.parameter.Specification.Protocol}</div>
                    </div>
                    <div class="mb-1 d-flex font-size-xs">
                        <div class="text-muted mr-1"> Параметр: </div>
                        <div class="font-weight-medium">{this.parameter.Specification.Parameter}</div>
                    </div>
                    {this.parameter.Specification.Key && (
                        <div class="mb-1 d-flex font-size-xs">
                            <div class="text-muted mr-1">Значение (HEX): </div>
                            <div class="font-weight-medium">{this.parameter.Specification.Key}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
