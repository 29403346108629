export default [
    {
        key: "none",
        value: 0
    },
    {
        key: "air",
        value: 1
    },
    {
        key: "rail",
        value: 2
    },
    {
        key: "water",
        value: 3
    },
    {
        key: "car",
        value: 4
    },
    {
        key: "truck",
        value: 5
    },
    {
        key: "special",
        value: 6
    },
    {
        key: "container",
        value: 7
    }
];
