export default [
    {
        key: "m",
        value: 0
    },
    {
        key: "km",
        value: 1
    },
    {
        key: "mile",
        value: 2
    },
    {
        key: "knot",
        value: 3
    }
];
