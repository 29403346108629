const settingsCards = [
    {
        key: "beaconsettings",
        permission: "CanEditBeaconsSettings",
        route: "beaconsettings",
        class: "fad fa-cog fa-2x color-glyph-primary"
    },
    {
        key: "remotecontrol",
        permission: "CanEditParameters",
        route: "remotecontrol",
        class: "fad fa-satellite-dish fa-2x color-glyph-primary"
    },
    {
        key: "card",
        permission: "CanEditTransportCard",
        route: "card",
        class: "fad fa-th-list fa-2x color-glyph-primary"
    },
    {
        key: "beaconnotifications",
        permission: "CanEditNotificationsSettings",
        route: "beaconnotifications",
        class: "fad fa-bell fa-2x color-glyph-primary"
    }
];

export { settingsCards };
