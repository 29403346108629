import { Vue, Component, Prop } from "vue-property-decorator";
import IParameter from "@/contracts/IParameter";
import parameterState from "@/const/parameter-state";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";

@Component
export default class ParameterPanelHeader extends Vue {
    @State
    user!: IUser;

    @Prop()
    parameter!: IParameter;

    @Prop()
    header!: string;

    @Prop({
        default: true
    })
    showResetButtons!: boolean;

    render() {
        return (
            <div>
                <div class="d-flex mb-3 justify-content-between">
                    <div class="font-weight-medium">{this.header}</div>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="d-flex flex-column align-items-start col-md-12 col-lg-3 mb-3">
                            <div class="font-size-xs text-muted">{this.$t("parameterCardHeader.lastSend")}</div>
                            <date-wrapper class="font-size-s" date={this.parameter.UpdatedDt} />
                        </div>
                        <div class="d-flex flex-column align-items-start col-md-12 col-lg-4 mb-3">
                            <div class="font-size-xs text-muted">{this.$t("parameterCardHeader.state")}</div>
                            <div class="font-size-s">{this.$t(parameterState[this.parameter.State].key)}</div>
                        </div>
                        <div class="col-md-12 col-lg-5 d-flex justify-content-lg-end mb-3">
                            {this.showResetButtons && (
                                <el-tooltip
                                    effect="dark"
                                    content={this.$t("parameterCardHeader.resetToDefault")}
                                    placement="top"
                                >
                                    <el-button
                                        onClick={() => this.$emit("resetToDefault")}
                                        icon="fas fa-lg fa-history"
                                        size="medium"
                                    />
                                </el-tooltip>
                            )}
                            <el-button onClick={() => this.$emit("save")} type="primary" size="medium">
                                {this.$t("parameterCardHeader.send")}
                            </el-button>
                            {this.user.CfgCanUseConfigTool && (
                                <el-tooltip
                                    effect="dark"
                                    content="Удалить параметр (действие нельзя отменить!)"
                                    placement="top"
                                >
                                    <el-button
                                        onClick={() => {
                                            this.$emit("delete");
                                        }}
                                        type="danger"
                                        size="medium"
                                        icon="fas fa-lg fa-trash-alt"
                                    ></el-button>
                                </el-tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
