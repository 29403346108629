import { Component, Mixins } from "vue-property-decorator";
import Panel from "@/components/Containers/Panel";
import ParameterPanelHeader from "./ParameterPanelHeader";
import ParameterTemplateMixin from "./ParameterTemplateMixin";

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class SsasDisableAlarm extends Mixins(ParameterTemplateMixin) {
    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    showResetButtons={true}
                    header={this.$t(`parameters.SSASDisableAlarm`)}
                    parameter={this.parameter}
                />
                <div>
                    <hr />
                    <div class="d-flex flex-column">
                        <el-input size="medium" placeholder="PIN" vModel={this.value.Pin} />
                    </div>
                </div>
            </panel>
        );
    }
}
