import { Vue, Component, Prop } from "vue-property-decorator";
import "./Breadcrumb.scss";
import ICrumb from "@/contracts/ICrumb";

@Component
export default class Breadcrumb extends Vue {
    @Prop()
    crumbs!: ICrumb[];

    render() {
        const crumbs = this.crumbs
            .filter(crumb => crumb)
            .map(crumb => (
                <el-breadcrumb-item key={crumb.name} to={{ name: crumb.name, params: crumb.params }}>
                    {crumb.label}
                </el-breadcrumb-item>
            ));

        return (
            <div class="breadcrumb-container px-3 py-3">
                <el-breadcrumb separator="/">{crumbs}</el-breadcrumb>
            </div>
        );
    }
}
