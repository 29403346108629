import { Component, Mixins } from "vue-property-decorator";
import Breadcrumb from "@/components/Menu/Breadcrumb";
import ImagePicker from "@/components/Select/ImagePicker.vue";
import ColorPicker from "@/components/Select/ColorPicker.vue";
import messages from "@/components/Mixins/messages";
import managementAPI from "@/api/management";
import { getFirmwares, Firmware, deleteFirmware, FirmwareState, updateFirmwareStatus } from "@/api/monitoring";
import Panel from "@/components/Containers/Panel";
import lengthUnits from "@/const/length-units";
import transportTypes from "@/const/transport-types";
import pictures from "@/const/beacon-pictures";
import colors from "@/const/colors";
import regexHolder from "@/const/string-validation-regex";
import cloneDeep from "lodash/cloneDeep";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import http from "@/api/http";

interface UserInterfaceSettings {
    Color: string;
    TrackLineWidth: number;
}

interface InterfaceSettings {
    Client1: string;
}

export enum VehicleType {
    None = 0,
    Air = 1,
    Rail = 2,
    Water = 3,
    Car = 4,
    Truck = 5,
    Special = 6,
    Container = 7
}

interface BeaconSettingsForm {
    Id: number;
    Name: string;
    CheckEmergency: boolean;
    CheckRelevance: boolean;
    Comment: string;
    DataRelevanceTimeout1: number;
    DataRelevanceTimeout2: number;
    EmergencyOverRelevancePriority: boolean;
    IconPath: string;
    InterfaceSettings: InterfaceSettings;
    LengthUnit: number;
    UserInterfaceSettings: UserInterfaceSettings;
    VehicleType: VehicleType;
    IridiumPort: string;
    IridiumHost: string;
    Specification: string;
}

@Component({
    components: {
        Panel,
        Breadcrumb,
        ImagePicker,
        ColorPicker
    }
})
export default class BeaconSettings extends Mixins(messages) {
    private form: BeaconSettingsForm | null = null;
    private beacon: BeaconSettingsForm | null = null;
    private working: boolean = false;
    private firmwareDescription: string = "";
    private firmwares: Firmware[] = [];

    @State
    user!: IUser;

    submitUpload() {
        (this.$refs.upload as any).submit();
    }

    get crumbs() {
        if (this.beacon) {
            return [
                {
                    label: this.$t("default.beacons"),
                    name: "beacons"
                },
                {
                    label: this.beacon.Name,
                    name: "beacon",
                    params: { id: this.beacon.Id }
                },
                {
                    label: this.$t("beaconsettings.settings"),
                    name: this.$route.name,
                    params: { id: this.beacon.Id }
                }
            ];
        }
        return [];
    }

    get uploadFirmware() {
        return `${http.defaults.baseURL}/firmware`;
    }

    private dataRelevanceTooltip(value) {
        let h = (value / 60) | 0;
        let m = value % 60 | 0;
        return (
            ("0" + h).slice(-2) + this.$t("datarelevance.hour") + ("0" + m).slice(-2) + this.$t("datarelevance.minute")
        );
    }

    private async load() {
        let beacon = await managementAPI.loadBeaconSettings(Number.parseInt(this.$route.params.id));
        this.beacon = { ...beacon };
        this.form = cloneDeep(beacon);

        if (this.user.CfgCanUseConfigTool) {
            this.firmwares = await getFirmwares(beacon.Id);
        }
    }

    private async save() {
        try {
            await (this.$refs["form"] as any).validate();
            this.working = true;
            if (this.beacon) {
                await managementAPI.updateBeaconSettings(this.beacon.Id, this.form);
                this.operationSuccess();
            }
        } catch (error) {
        } finally {
            this.working = false;
        }
    }

    private discard() {
        this.form = cloneDeep(this.beacon);
    }

    private rules() {
        return {
            Name: [
                {
                    required: true,
                    pattern: regexHolder["settings_name"].value,
                    message: "",
                    trigger: "blur"
                }
            ],
            Comment: [
                {
                    pattern: regexHolder["settings_comment"].value,
                    message: "",
                    trigger: "blur"
                }
            ],
            "InterfaceSettings.Client1": [
                {
                    pattern: regexHolder["settings_name"].value,
                    message: "",
                    trigger: "blur"
                }
            ]
        };
    }

    public created() {
        this.load();
    }

    public async onFirmwareUploadSuccess() {
        if (this.user.CfgCanUseConfigTool && this.beacon) {
            this.firmwares = await getFirmwares(this.beacon.Id);
        }
    }

    public async onDeleteFirmware(id: number) {
        await deleteFirmware(id);
        if (this.user.CfgCanUseConfigTool && this.beacon) {
            this.firmwares = await getFirmwares(this.beacon.Id);
        }
    }

    public async handleCommand(id: number, command: string) {
        if (this.user.CfgCanUseConfigTool && this.beacon) {
            switch (command) {
                case "send":
                    await updateFirmwareStatus(id, FirmwareState.Waiting);
                    this.firmwares = await getFirmwares(this.beacon.Id);
                    break;
                case "cancel":
                    await updateFirmwareStatus(id, FirmwareState.None);
                    this.firmwares = await getFirmwares(this.beacon.Id);
                    break;
                case "delete":
                    await this.onDeleteFirmware(id);
                    break;
            }
        }
    }

    private hostSuggestions: string[] = [];

    public async fetchHostSuggestions(queryString, cb) {
        this.hostSuggestions = await managementAPI.loadHostSuggestions();
        cb(
            this.hostSuggestions.map(x => ({
                value: x
            }))
        );
    }

    private specificationSuggestions: string[] = [];

    public async fetchSpecificationSuggestions(queryString, cb) {
        this.specificationSuggestions = await managementAPI.loadSpecificationSuggestions();
        cb(
            this.specificationSuggestions.map(x => ({
                value: x
            }))
        );
    }

    private portSuggestions: string[] = [];

    public async fetchPortSuggestions(queryString, cb) {
        this.portSuggestions = await managementAPI.loadPortSuggestions();
        cb(
            this.portSuggestions.map(x => ({
                value: x
            }))
        );
    }

    public render() {
        if (!this.beacon || !this.form) {
            return null;
        }
        return (
            <div class="d-flex flex-column h-100">
                <breadcrumb crumbs={this.crumbs} />
                <div class="scroll h-100">
                    <div class="container-fluid">
                        <div class="row justify-content-md-center">
                            <el-form
                                rules={this.rules()}
                                ref="form"
                                {...{ attrs: { model: this.form } }}
                                size="medium"
                                show-message={false}
                                label-position="left"
                                class="col-lg-8 col-xl-5 col-md-12 text-left py-3"
                            >
                                <panel header={this.$t("beaconsettings.settings")}>
                                    <el-form-item label={this.$t("default.name")} prop="Name">
                                        <el-input vModel={this.form.Name} maxlength={250} />
                                    </el-form-item>
                                    <el-form-item label={this.$t("default.comment")} prop="Comment">
                                        <el-input type="textarea" maxlength={400} rows={4} vModel={this.form.Comment} />
                                    </el-form-item>
                                    <el-form-item label={this.$t("beaconsettings.LengthUnit")}>
                                        <el-select vModel={this.form.LengthUnit}>
                                            {lengthUnits.map(lengthUnit => (
                                                <el-option
                                                    key={lengthUnit.value}
                                                    label={this.$t(`enums.lengthUnits.${lengthUnit.key}`)}
                                                    value={lengthUnit.value}
                                                />
                                            ))}
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label={this.$t("beaconsettings.VehicleType")}>
                                        <el-select vModel={this.form.VehicleType}>
                                            {transportTypes.map(transportType => (
                                                <el-option
                                                    key={transportType.value}
                                                    label={this.$t(`enums.transporttypes.${transportType.key}`)}
                                                    value={transportType.value}
                                                />
                                            ))}
                                        </el-select>
                                    </el-form-item>
                                    {this.form.UserInterfaceSettings && (
                                        <div>
                                            <el-form-item style="flex: 1;" label={this.$t("default.icon")}>
                                                <image-picker
                                                    items={pictures}
                                                    vModel={this.form.IconPath}
                                                    background-color={this.form.UserInterfaceSettings.Color}
                                                />
                                            </el-form-item>
                                            <el-form-item>
                                                <div class="d-flex flex-column">
                                                    <div class="font-weight-medium">{this.$t("default.color")}</div>
                                                    <color-picker
                                                        items={colors}
                                                        vModel={this.form.UserInterfaceSettings.Color}
                                                    />
                                                </div>
                                            </el-form-item>
                                        </div>
                                    )}
                                </panel>
                                <panel header={this.$t("beaconsettings.Emergency")}>
                                    <el-form-item>
                                        <div class="d-flex justify-content-between align-items-center font-weight-medium">
                                            {this.$t("beaconsettings.CheckEmergency")}
                                            <el-switch vModel={this.form.CheckEmergency} />
                                        </div>
                                    </el-form-item>
                                    {/* <el-form-item>
                                        <div class="d-flex justify-content-between align-items-center font-weight-medium">
                                            {this.$t("beaconsettings.EmergencyOverRelevancePriority")}
                                            <el-switch vModel={this.form.EmergencyOverRelevancePriority} />
                                        </div>
                                    </el-form-item> */}
                                </panel>
                                <panel header={this.$t("datarelevance.datarelevance")}>
                                    <el-form-item>
                                        <div class="d-flex justify-content-between align-items-center font-weight-medium">
                                            {this.$t("beaconsettings.CheckRelevance")}
                                            <el-switch vModel={this.form.CheckRelevance} />
                                        </div>
                                    </el-form-item>
                                    <el-form-item label={this.$t("datarelevance.relevancetimeout1")}>
                                        <el-slider
                                            class="slider"
                                            min={60}
                                            max={4200}
                                            show-input
                                            vModel={this.form.DataRelevanceTimeout1}
                                            format-tooltip={this.dataRelevanceTooltip}
                                        />
                                    </el-form-item>
                                    <el-form-item label={this.$t("datarelevance.relevancetimeout2")}>
                                        <el-slider
                                            class="slider"
                                            min={60}
                                            max={4200}
                                            show-input
                                            vModel={this.form.DataRelevanceTimeout2}
                                            format-tooltip={this.dataRelevanceTooltip}
                                        />
                                    </el-form-item>
                                </panel>
                                <panel header={this.$t("beaconsettings.messenger")}>
                                    <el-form-item
                                        label={this.$t("beaconsettings.client")}
                                        prop="InterfaceSettings.Client1"
                                    >
                                        <el-input vModel={this.form.InterfaceSettings.Client1} maxlength={250} />
                                    </el-form-item>
                                </panel>
                                {this.user.CfgCanUseConfigTool && (
                                    <panel header={this.$t("beaconsettings.updateProgrammFiles")}>
                                        <el-table data={this.firmwares} empty-text={this.$t("default.nodata")} fit>
                                            <el-table-column
                                                sortable
                                                show-overflow-tooltip
                                                min-width="150"
                                                label={this.$t("default.name")}
                                                prop="FirmwareDescription"
                                            />
                                            <el-table-column
                                                min-width="120"
                                                align="left"
                                                label={this.$t("beaconsettings.firmwareSize")}
                                                {...{
                                                    scopedSlots: {
                                                        default: (scope: { row: Firmware }) => {
                                                            return (
                                                                <div
                                                                    class="beacon-channels__value"
                                                                    style="min-width: 0"
                                                                >
                                                                    {scope.row.FirmwareSize
                                                                        ? scope.row.FirmwareSize
                                                                        : "-"}
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                            <el-table-column
                                                label={this.$t("beaconsettings.addingTime")}
                                                min-width="160"
                                                {...{
                                                    scopedSlots: {
                                                        default: (scope: { row: Firmware }) => {
                                                            return (
                                                                <div class="beacon-channels__timestamp">
                                                                    <date-wrapper
                                                                        date={scope.row.CreatedDt}
                                                                        format="DD.MM.YY HH:mm"
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                            <el-table-column
                                                label={this.$t("beaconsettings.timeLastOperation")}
                                                min-width="160"
                                                {...{
                                                    scopedSlots: {
                                                        default: (scope: { row: Firmware }) => {
                                                            return (
                                                                <div class="beacon-channels__timestamp">
                                                                    <date-wrapper
                                                                        date={scope.row.LastOperationDt}
                                                                        format="DD.MM.YY HH:mm"
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                }}
                                            />{" "}
                                            <el-table-column
                                                label={this.$t("beaconsettings.status")}
                                                min-width="160"
                                                {...{
                                                    scopedSlots: {
                                                        default: (scope: { row: Firmware }) => {
                                                            switch (scope.row.Status) {
                                                                case FirmwareState.Complete:
                                                                    return this.$t(
                                                                        "beaconsettings.successfullyCompleted"
                                                                    );
                                                                case FirmwareState.Error:
                                                                    return this.$t("beaconsettings.error");
                                                                case FirmwareState.None:
                                                                    return this.$t("beaconsettings.nothingToDo");
                                                                case FirmwareState.Waiting:
                                                                    return this.$t(
                                                                        "beaconsettings.waitingBeaconCommunicate"
                                                                    );
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                            <el-table-column
                                                label={this.$t("default.actions")}
                                                width="100"
                                                {...{
                                                    scopedSlots: {
                                                        default: (scope: { row: Firmware }) => {
                                                            return (
                                                                <div class="d-flex align-items-center justify-content-center">
                                                                    <el-dropdown
                                                                        size="small"
                                                                        trigger="click"
                                                                        onCommand={command =>
                                                                            this.handleCommand(scope.row.Id, command)
                                                                        }
                                                                    >
                                                                        <el-button
                                                                            size="small"
                                                                            icon="fas fa-ellipsis-v fa-lg"
                                                                        />
                                                                        <el-dropdown-menu slot="dropdown">
                                                                            <el-dropdown-item
                                                                                class="mini-beacon-card__command"
                                                                                command="send"
                                                                            >
                                                                                {this.$t("beaconsettings.queue")}
                                                                            </el-dropdown-item>
                                                                            <el-dropdown-item
                                                                                class="mini-beacon-card__command"
                                                                                command="cancel"
                                                                            >
                                                                                {this.$t(
                                                                                    "beaconsettings.cancelSending"
                                                                                )}
                                                                            </el-dropdown-item>
                                                                            <el-dropdown-item
                                                                                class="mini-beacon-card__command"
                                                                                command="delete"
                                                                            >
                                                                                {this.$t("beaconsettings.delete")}
                                                                            </el-dropdown-item>
                                                                        </el-dropdown-menu>
                                                                    </el-dropdown>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                            )}
                                        </el-table>
                                        <el-form-item label={this.$t("beaconsettings.description")}>
                                            <el-input
                                                type="textarea"
                                                maxlength={300}
                                                rows={3}
                                                vModel={this.firmwareDescription}
                                            />
                                        </el-form-item>
                                        <el-upload
                                            class="upload-demo"
                                            ref="upload"
                                            {...{
                                                attrs: {
                                                    onSuccess: this.onFirmwareUploadSuccess
                                                }
                                            }}
                                            action={`${this.uploadFirmware}`}
                                            headers={{
                                                Authorization: "Bearer " + localStorage.getItem("token")
                                            }}
                                            data={{
                                                BeaconId: this.beacon.Id,
                                                FirmwareDescription: this.firmwareDescription
                                            }}
                                            auto-upload={false}
                                        >
                                            <el-button slot="trigger" size="small" type="primary">
                                                {this.$t("beaconsettings.selectFile")}
                                            </el-button>
                                            <el-button
                                                style="margin-left: 10px;"
                                                size="small"
                                                type="success"
                                                onClick={this.submitUpload}
                                            >
                                                {this.$t("beaconsettings.queue")}
                                            </el-button>
                                        </el-upload>
                                    </panel>
                                )}
                                {this.user.CfgCanUseConfigTool && (
                                    <panel header={this.$t("beaconsettings.settingsSendingCommandsToIridium")}>
                                        <el-form-item
                                            label={this.$t("beaconsettings.specification")}
                                            prop="Specification"
                                        >
                                            <el-autocomplete
                                                class="w-100"
                                                fetch-suggestions={this.fetchSpecificationSuggestions}
                                                vModel={this.form.Specification}
                                                maxlength={500}
                                            />
                                        </el-form-item>
                                        <el-form-item
                                            label={this.$t("beaconsettings.iridiumGatewayHost")}
                                            prop="IridiumHost"
                                        >
                                            <el-autocomplete
                                                class="w-100"
                                                fetch-suggestions={this.fetchHostSuggestions}
                                                vModel={this.form.IridiumHost}
                                                maxlength={500}
                                            />
                                        </el-form-item>
                                        <el-form-item
                                            label={this.$t("beaconsettings.iridiumGatewayPort")}
                                            prop="IridiumPort"
                                        >
                                            <el-autocomplete
                                                class="w-100"
                                                fetch-suggestions={this.fetchPortSuggestions}
                                                vModel={this.form.IridiumPort}
                                                maxlength={500}
                                            />
                                        </el-form-item>
                                    </panel>
                                )}
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="bottom-bar">
                    <div class="container-fluid">
                        <div class="row justify-content-md-center">
                            <div class="col-lg-8 col-xl-5 col-md-12 d-flex">
                                <el-button onClick={this.save} size="small" type="primary" loading={this.working}>
                                    {this.$t("default.apply")}
                                </el-button>
                                <el-button onClick={this.discard} size="small" disabled={this.working}>
                                    {this.$t("default.reset")}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
