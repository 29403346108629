import { Component, Mixins } from "vue-property-decorator";
import Panel from "@/components/Containers/Panel";
import RadioGroupEnums from "./radioGroupEnums";
import ParameterPanelHeader from "./ParameterPanelHeader";
import ParameterTemplateMixin from "./ParameterTemplateMixin";

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class ChannelsConfigTemplate extends Mixins(ParameterTemplateMixin) {
    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    header={this.$t("channelsConfigParameter.title")}
                    parameter={this.parameter}
                />
                <hr />
                <div class="d-flex flex-column">
                    <div class="w-100 text-left mt-3">{this.$t("channelsConfigParameter.measurement")}</div>
                    <el-radio-group vModel={this.value.AnalogFlag} class="w-100 d-flex flex-column align-items-start">
                        {RadioGroupEnums.analogFlag.map(enumValue => (
                            <el-radio class="mx-0 my-1" key={enumValue.value} label={enumValue.value}>
                                {this.$t(`enums.analogFlag.${enumValue.key}`)}
                            </el-radio>
                        ))}
                    </el-radio-group>
                    <div class="w-100 text-left mt-3">{this.$t("channelsConfigParameter.analogMode")}</div>
                    <el-radio-group vModel={this.value.AnalogMode} class="w-100 d-flex flex-column align-items-start">
                        {RadioGroupEnums.analogMode.map(enumValue => (
                            <el-radio class="mx-0 my-1" key={enumValue.value} label={enumValue.value}>
                                {this.$t(`enums.analogMode.${enumValue.key}`)}
                            </el-radio>
                        ))}
                    </el-radio-group>
                    <div class="w-100 text-left mt-3">{this.$t("channelsConfigParameter.analogPeriod")}</div>
                    <el-input-number
                        disabled={this.value.AnalogMode !== 2}
                        size="medium"
                        vModel={this.value.AnalogInterval}
                    />
                    <div class="w-100 text-left mt-3">{this.$t("channelsConfigParameter.discreteMode")}</div>
                    <el-radio-group vModel={this.value.DiscreteMode} class="w-100 d-flex flex-column align-items-start">
                        {RadioGroupEnums.discreteMode.map(enumValue => (
                            <el-radio class="mx-0 my-1" key={enumValue.value} label={enumValue.value}>
                                {this.$t(`enums.discreteMode.${enumValue.key}`)}
                            </el-radio>
                        ))}
                    </el-radio-group>
                    <div class="w-100 text-left mt-3">{this.$t("channelsConfigParameter.discretePeriod")}</div>
                    <el-input-number
                        disabled={this.value.DiscreteMode !== 2}
                        size="medium"
                        vModel={this.value.DiscreteInterval}
                    />
                </div>
            </panel>
        );
    }
}
