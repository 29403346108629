//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import managementAPI from "@/api/management";

import Breadcrumb from "@/components/Menu/Breadcrumb";
import SettingsCard from "./SettingsCard";
import ChannelsTable from "./ChannelsTable";

import { settingsCards } from "./enums";

export default {
    name: "BeaconManagement",
    components: {
        Breadcrumb,
        SettingsCard,
        ChannelsTable
    },
    data() {
        return {
            settingsCards,
            beacon: null,
            channels: null
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        crumbs() {
            let path1 = {
                label: this.$t("default.beacons"),
                name: "beacons"
            };
            let path2 = {
                label: this.beacon.Name,
                name: this.$route.name,
                params: { id: this.beacon.Id }
            };
            return [path1, path2];
        }
    },
    methods: {
        handleChannel(channel) {
            this.$router.push({
                name: "channel",
                params: { id: this.beacon.Id, channelid: channel.Id }
            });
        },
        handleClick(route) {
            this.$router.push({
                name: route,
                params: { id: this.beacon.Id }
            });
        }
    },
    beforeCreate() {
        managementAPI.loadBeacon(Number.parseInt(this.$route.params.id)).then(data => {
            this.beacon = data;
        });
        managementAPI.loadChannels(Number.parseInt(this.$route.params.id)).then(channels => {
            this.channels = channels;
        });
    }
};
