import IBeaconUserInterfaceSettings from "@/contracts/IBeaconUserInterfaceSettings";

interface IBeaconManagement {
    CanEditAdvancedParameters: boolean;
    CanEditBeaconsSettings: boolean;
    CanEditChannelsSettings: boolean;
    CanEditNotificationsSettings: boolean;
    CanEditParameters: boolean;
    CanEditTransportCard: boolean;
    CanReadMessages: boolean;
    CanSendMessages: boolean;
    CanShareBeacons: boolean;
    CanViewMessages: boolean;
    Comment: string;
    GsmImei: string;
    IconPath: string;
    Id: number;
    IridiumImei: string;
    IsOwner: boolean;
    Name: string;
    SerialNumber: string;
    UserInterfaceSettings: IBeaconUserInterfaceSettings;
    PreferredCommunicationType: CommunicationType;
    LastCommunicationType?: CommunicationType;
}

export enum CommunicationType {
    GSM = 0,
    Iridium = 1
}

export default IBeaconManagement;
