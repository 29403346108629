export default {
    DataChannel: [
        {
            key: "OnlyIridium",
            value: 1
        },
        {
            key: "OnlyGSM",
            value: 2
        },
        {
            key: "PriorityIridium",
            value: 3
        },
        {
            key: "PriorityGSM",
            value: 4
        }
    ],
    ExchangeMode: [
        {
            key: "Interval",
            value: 1
        },
        {
            key: "Schedule",
            value: 2
        }
    ],
    analogFlag: [
        {
            key: "voltage",
            value: 0
        },
        {
            key: "current",
            value: 1
        }
    ],
    analogMode: [
        {
            key: "dontSaveData",
            value: 0
        },
        {
            key: "saveDataWithCoordinates",
            value: 1
        },
        {
            key: "saveDataWithInterval",
            value: 2,
            hasInputValue: true
        }
    ],
    discreteMode: [
        {
            key: "dontSaveData",
            value: 0
        },
        {
            key: "saveDataWithCoordinates",
            value: 1
        },
        {
            key: "saveDataWithInterval",
            value: 2,
            hasInputValue: true
        },
        {
            key: "saveDataWithStateChange",
            value: 3
        }
    ],
    nmeaMode: [
        {
            key: "nmeaOff",
            value: 0
        },
        {
            key: "nmeaOn",
            value: 1
        },
        {
            key: "adaptiveMode",
            value: 2
        }
    ],
    navMessageFormat: [
        {
            key: "dynamicFormation",
            value: 0
        },
        {
            key: "onlyCoordAndTime",
            value: 1
        }
    ],
    dataChannel: [
        {
            key: "onlyIridium",
            value: 1
        },
        {
            key: "onlyGSM",
            value: 2
        },
        {
            key: "priorityIridium",
            value: 3
        },
        {
            key: "priorityGSM",
            value: 4
        }
    ]
};
