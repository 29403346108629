import { Component, Mixins } from "vue-property-decorator";
import Panel from "@/components/Containers/Panel";
import RadioGroupEnums from "./radioGroupEnums";
import ParameterPanelHeader from "./ParameterPanelHeader";

import ParameterTemplateMixin from "./ParameterTemplateMixin";

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class StatusConfigurationTemplate extends Mixins(ParameterTemplateMixin) {
    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    header={this.$t("statusConfigurationParameter.title")}
                    parameter={this.parameter}
                />
                <hr />
                <div class="w-100 text-left my-3">{this.$t("statusConfigurationParameter.statusesSettings")}</div>
                <el-checkbox class="mb-2" vModel={this.value.HardReset} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.HardReset`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.SoftReset} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.SoftReset`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.TimerReset} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.TimerReset`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.CaseOpen} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.CaseOpen`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.CaseClose} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.CaseClose`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.GPSNotAvailable} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.GPSNotAvailable`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.IridiumNotAvailable} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.IridiumNotAvailable`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.GSMNotAvailable} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.GSMNotAvailable`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.LowBattery} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.LowBattery`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.MountUninstall} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.MountUninstall`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.MountInstall} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.MountInstall`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.PowerConnected} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.PowerConnected`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.PowerDisconnected} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.PowerDisconnected`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.ExternalVoltageLow} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.ExternalVoltageLow`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.ExternalVoltageHigh} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.ExternalVoltageHigh`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.ExternalVoltageNormal} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.ExternalVoltageNormal`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.InternalVoltageLow} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.InternalVoltageLow`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.InternalVoltageNormal} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.InternalVoltageNormal`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.BoardTempLow} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.BoardTempLow`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.BoardTempHigh} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.BoardTempHigh`)}
                </el-checkbox>
                <el-checkbox class="mb-2" vModel={this.value.BoardTempNormal} onChange={() => {}}>
                    {this.$t(`statusConfigurationParameter.BoardTempNormal`)}
                </el-checkbox>

                <div class="w-100 text-left my-3">{this.$t("statusConfigurationParameter.navMessageSettings")}</div>
                <el-radio-group vModel={this.value.MessageFormat} class="w-100 d-flex flex-column align-items-start">
                    {RadioGroupEnums.navMessageFormat.map(enumValue => (
                        <el-radio class="mx-0 my-1" key={enumValue.value} label={enumValue.value}>
                            {this.$t(`enums.navMessageFormat.${enumValue.key}`)}
                        </el-radio>
                    ))}
                </el-radio-group>
            </panel>
        );
    }
}
