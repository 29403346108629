import Breadcrumb from "@/components/Menu/Breadcrumb";
import binaryChannelStatuses from "@/const/binary-channel-statuses";
import managementAPI from "@/api/management";
import regexHolder from "@/const/string-validation-regex";
import Panel from "@/components/Containers/Panel";
import IChannelSettings from "@/contracts/IChannelSettings";
import IChannelSettingsForm from "@/contracts/IChannelSettingsForm";
import Messages from "@/components/Mixins/messages";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        Panel,
        Breadcrumb
    }
})
export default class ChannelSettings extends Mixins(Messages) {
    binaryChannelStatuses: { key: string; value: number }[] = binaryChannelStatuses;
    form: IChannelSettingsForm | null = null;
    channel: any = null;
    beacon: any = null;
    working: boolean = false;
    regexHolder = regexHolder;

    private get crumbs(): any[] {
        let path1 = {
            label: this.$t("default.beacons"),
            name: "beacons"
        };
        let path2 = {
            label: this.beacon.Name,
            name: "beacon",
            params: { id: this.beacon.Id }
        };
        let path3 = {
            label: this.channel.Name,
            name: this.$route.name,
            params: { id: this.beacon.Id, channelid: this.channel.Id }
        };
        return [path1, path2, path3];
    }

    dataRelevanceTooltip(value) {
        let h = (value / 60) | 0;
        let m = value % 60 | 0;
        return (
            ("0" + h).slice(-2) + this.$t("datarelevance.hour") + ("0" + m).slice(-2) + this.$t("datarelevance.minute")
        );
    }

    load() {
        managementAPI
            .loadChannelSettings(Number.parseInt(this.$route.params.id), Number.parseInt(this.$route.params.channelid))
            .then(channel => {
                if (channel) {
                    this.setChannel(channel);
                }
            });
    }

    save() {
        var form: any = this.$refs["form"];
        form.validate(valid => {
            if (valid) {
                this.working = true;
                managementAPI
                    .updateChannelSettings(
                        Number.parseInt(this.$route.params.id),
                        Number.parseInt(this.$route.params.channelid),
                        this.form
                    )
                    .then(() => {
                        this.operationSuccess();
                    })
                    .finally(() => {
                        this.working = false;
                    });
            } else {
                return false;
            }
        });
    }

    discard() {
        this.setChannel(this.channel);
    }

    setChannel(data: IChannelSettings) {
        this.channel = { ...data };
        this.form = data;
    }

    loadBeacon() {
        managementAPI.loadBeacon(Number.parseInt(this.$route.params.id)).then(responce => {
            this.beacon = responce;
        });
    }

    created() {
        this.load();
        this.loadBeacon();
    }

    render() {
        if (!this.channel || !this.beacon || !this.form) {
            return null;
        }
        return (
            <div class="d-flex flex-column">
                <breadcrumb crumbs={this.crumbs} />
                <div class="scroll h-100">
                    <div class="container-fluid">
                        <div class="row justify-content-md-center">
                            <el-form
                                {...{ attrs: { model: this.form } }}
                                size="small"
                                ref="form"
                                show-message={false}
                                label-position="left"
                                class="col-lg-8 col-xl-5 col-md-12 text-left py-3"
                            >
                                <panel header={this.$t("beaconsettings.settings")}>
                                    <el-form-item
                                        label={this.$t("default.name")}
                                        prop="Name"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: regexHolder["settings_name"].value,
                                                message: "",
                                                trigger: "blur"
                                            }
                                        ]}
                                    >
                                        <el-input vModel={this.form.Name} maxlength={100} />
                                    </el-form-item>
                                    <el-form-item
                                        label={this.$t("default.comment")}
                                        prop="Comment"
                                        rules={[
                                            {
                                                pattern: regexHolder["settings_comment"].value,
                                                message: "",
                                                trigger: "blur"
                                            }
                                        ]}
                                    >
                                        <el-input type="textarea" maxlength={400} rows={4} vModel={this.form.Comment} />
                                    </el-form-item>
                                    {this.channel.ChannelType === 0 && (
                                        <el-form-item>
                                            <div class="d-flex justify-content-between align-items-center font-weight-medium">
                                                {this.$t("channelsettings.padleadingzeros")}
                                                <el-switch vModel={this.form.AddInsignificantZeros} />
                                            </div>
                                        </el-form-item>
                                    )}
                                    {this.channel.ChannelType === 0 && (
                                        <el-form-item
                                            class="d-flex flex-column"
                                            label={this.$t("channelsettings.precision")}
                                        >
                                            <el-input-number min={0} max={5} vModel={this.form.ValuePrecision} />
                                        </el-form-item>
                                    )}
                                    {this.channel.ChannelType === 1 && (
                                        <div class="row">
                                            <div class="col">
                                                <el-form-item label={this.$t("channelsettings.binarychannelvalue0")}>
                                                    <el-input vModel={this.form.BinaryChannelValue0} />
                                                </el-form-item>
                                            </div>
                                            <div class="col">
                                                <el-form-item label={this.$t("channelsettings.binarychannelvalue1")}>
                                                    <el-input vModel={this.form.BinaryChannelValue1} />
                                                </el-form-item>
                                            </div>
                                        </div>
                                    )}
                                </panel>

                                <panel header={this.$t("channelsettings.emergencystatus")}>
                                    <el-form-item>
                                        <div class="d-flex justify-content-between align-items-center font-weight-medium">
                                            {this.$t("channelsettings.determineemergencystatus")}
                                            <el-switch vModel={this.form.CheckEmergency} />
                                        </div>
                                    </el-form-item>
                                    {this.channel.ChannelType === 0 && (
                                        <div class="row">
                                            <div class="col">
                                                <el-form-item
                                                    label={this.$t("channelsettings.lowerpreemergencythreshold")}
                                                    class="d-flex flex-column"
                                                >
                                                    <el-input-number
                                                        min={this.form.LowerEmergencyThreshold}
                                                        max={this.form.UpperPreEmergencyThreshold}
                                                        disabled={!this.form.CheckEmergency}
                                                        vModel={this.form.LowerPreEmergencyThreshold}
                                                    />
                                                </el-form-item>
                                            </div>
                                            <div class="col">
                                                <el-form-item
                                                    label={this.$t("channelsettings.upperpreemergencythreshold")}
                                                    class="d-flex flex-column"
                                                >
                                                    <el-input-number
                                                        min={this.form.LowerPreEmergencyThreshold}
                                                        max={this.form.UpperEmergencyThreshold}
                                                        disabled={!this.form.CheckEmergency}
                                                        vModel={this.form.UpperPreEmergencyThreshold}
                                                    />
                                                </el-form-item>
                                            </div>
                                        </div>
                                    )}
                                    {this.channel.ChannelType === 0 && (
                                        <div class="row">
                                            <div class="col">
                                                <el-form-item
                                                    label={this.$t("channelsettings.loweremergencythreshold")}
                                                    class="d-flex flex-column"
                                                >
                                                    <el-input-number
                                                        min={this.form.Min}
                                                        max={this.form.LowerPreEmergencyThreshold}
                                                        disabled={!this.form.CheckEmergency}
                                                        vModel={this.form.LowerEmergencyThreshold}
                                                    />
                                                </el-form-item>
                                            </div>
                                            <div class="col">
                                                <el-form-item
                                                    label={this.$t("channelsettings.upperemergencythreshold")}
                                                    class="d-flex flex-column"
                                                >
                                                    <el-input-number
                                                        min={this.form.UpperPreEmergencyThreshold}
                                                        max={this.form.Max}
                                                        disabled={!this.form.CheckEmergency}
                                                        vModel={this.form.UpperEmergencyThreshold}
                                                    />
                                                </el-form-item>
                                            </div>
                                        </div>
                                    )}
                                    {this.channel.ChannelType === 1 && (
                                        <div class="row">
                                            <div class="col">
                                                <el-form-item label={this.$t("channelsettings.binarychannelstatus0")}>
                                                    <el-select
                                                        disabled={!this.form.CheckEmergency}
                                                        vModel={this.form.BinaryChannelStatus0}
                                                    >
                                                        {this.binaryChannelStatuses.map(status => (
                                                            <el-option
                                                                key={status.value}
                                                                value={status.value}
                                                                label={this.$t(
                                                                    `enums.binaryChannelStatuses.${status.key}`
                                                                )}
                                                            />
                                                        ))}
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div class="col">
                                                <el-form-item label={this.$t("channelsettings.binarychannelstatus1")}>
                                                    <el-select
                                                        disabled={!this.form.CheckEmergency}
                                                        vModel={this.form.BinaryChannelStatus1}
                                                    >
                                                        {this.binaryChannelStatuses.map(status => (
                                                            <el-option
                                                                key={status.value}
                                                                value={status.value}
                                                                label={this.$t(
                                                                    `enums.binaryChannelStatuses.${status.key}`
                                                                )}
                                                            />
                                                        ))}
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    )}
                                </panel>
                                <panel header={this.$t("datarelevance.datarelevance")}>
                                    <el-form-item>
                                        <div class="d-flex justify-content-between align-items-center font-weight-medium">
                                            {this.$t("beaconsettings.CheckRelevance")}
                                            <el-switch vModel={this.form.CheckRelevance} />
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <div class="d-flex justify-content-between align-items-center font-weight-medium">
                                            {this.$t("datarelevance.usebeacontimeouts")}
                                            <el-switch
                                                disabled={!this.form.CheckRelevance}
                                                vModel={this.form.UseBeaconTimeouts}
                                            />
                                        </div>
                                    </el-form-item>
                                    <el-form-item label={this.$t("datarelevance.relevancetimeout1")}>
                                        <el-slider
                                            class="slider"
                                            min={60}
                                            max={4200}
                                            show-input
                                            disabled={this.form.UseBeaconTimeouts || !this.form.CheckRelevance}
                                            format-tooltip={this.dataRelevanceTooltip}
                                            vModel={this.form.DataRelevanceTimeout1}
                                        />
                                    </el-form-item>
                                    <el-form-item label={this.$t("datarelevance.relevancetimeout2")}>
                                        <el-slider
                                            class="slider"
                                            min={60}
                                            max={4200}
                                            show-input
                                            disabled={this.form.UseBeaconTimeouts || !this.form.CheckRelevance}
                                            format-tooltip={this.dataRelevanceTooltip}
                                            vModel={this.form.DataRelevanceTimeout2}
                                        />
                                    </el-form-item>
                                </panel>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="bottom-bar">
                    <div class="container-fluid">
                        <div class="row justify-content-md-center">
                            <div class="col-lg-8 col-xl-5 col-md-12 d-flex">
                                <el-button onClick={this.save} size="small" type="primary" loading={this.working}>
                                    {this.$t("default.apply")}
                                </el-button>
                                <el-button onClick={this.discard} size="small" disabled={this.working}>
                                    {this.$t("default.reset")}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
