const parameterState = {
    0: {
        key: "parameterState.notSent",
        value: 0,
        class: "beacon-parameter__status beacon-parameter__status--none"
    },
    1: {
        key: "parameterState.waiting",
        value: 1,
        class: "beacon-parameter__status beacon-parameter__status--pending"
    },
    2: {
        key: "parameterState.sent",
        value: 2,
        class: "beacon-parameter__status beacon-parameter__status--sent"
    },
    3: {
        key: "parameterState.sentToIridium",
        value: 3,
        class: "beacon-parameter__status beacon-parameter__status--sent"
    },
    4: {
        key: "parameterState.sentAndConfirmed",
        value: 4,
        class: "beacon-parameter__status beacon-parameter__status--sent"
    },
    5: {
        key: "parameterState.error",
        value: 5,
        class: "beacon-parameter__status beacon-parameter__status--error"
    }
};

export default parameterState;
// None,
// Waiting,
// Sent,
// SentAndConfirmedByIridium,
// SentAndConfirmed,
// Error
