import Breadcrumb from "@/components/Menu/Breadcrumb";
import managementApi from "@/api/management";
import Panel from "@/components/Containers/Panel";

import { Component, Vue } from "vue-property-decorator";
import IBeaconManagement, { CommunicationType } from "@/contracts/IBeaconManagement";
import IParameter from "@/contracts/IParameter";
import StatusConfigurationTemplate from "./ParameterTemplates/StatusConfigurationTemplate";
import CollectModeConfigurationTemplate from "./ParameterTemplates/CollectModeConfigurationTemplate";
import ExchangeModeConfigurationTemplate from "./ParameterTemplates/ExchangeModeConfigurationTemplate";
import ChannelsConfigTemplate from "./ParameterTemplates/ChannelsConfigTemplate";
import NmeaModeConfigurationTemplate from "./ParameterTemplates/NmeaModeConfigurationTemplate";
import NumberParameterTemplate from "./ParameterTemplates/NumberParameterTemplate";
import SsasConfigTemplate from "./ParameterTemplates/SsasConfigTemplate";
import LritConfigTemplate from "./ParameterTemplates/LritConfigTemplate";
import LritEnableTemplate from "./ParameterTemplates/LritEnableTemplate";
import LritPeriodTemplate from "./ParameterTemplates/LritPeriodTemplate";
import SsasDisableAlarmTemplate from "./ParameterTemplates/SsasDisableAlarmTemplate";
import CustomCommandTemplate from "./ParameterTemplates/CustomCommandTemplate";
import ModbusConfigurationTemplate from "./ParameterTemplates/ModbusConfigurationTemplate";
import ModbusCollectModeConfigurationTemplate from "./ParameterTemplates/ModbusCollectModeConfigurationTemplate";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import AddParameterModal from "./AddParameterModal";

@Component({
    components: {
        Breadcrumb,
        Panel,
        StatusConfigurationTemplate,
        CollectModeConfigurationTemplate,
        ExchangeModeConfigurationTemplate,
        ChannelsConfigTemplate,
        NmeaModeConfigurationTemplate,
        NumberParameterTemplate,
        SsasConfigTemplate,
        LritConfigTemplate,
        LritEnableTemplate,
        LritPeriodTemplate,
        SsasDisableAlarmTemplate,
        CustomCommandTemplate,
        AddParameterModal,
        ModbusConfigurationTemplate,
        ModbusCollectModeConfigurationTemplate
    }
})
export default class BeaconRemoteControl extends Vue {
    private beacon: IBeaconManagement | null | undefined = null;
    private parameters: IParameter[] = [];
    private loading: boolean = true;
    private communicationType: CommunicationType = CommunicationType.GSM;
    private addParamterModalIsOpen: boolean = false;

    @State
    user!: IUser;

    private toggleAddParameterModal() {
        this.addParamterModalIsOpen = !this.addParamterModalIsOpen;
    }

    private changeCommunicationType(value) {
        if (this.beacon) {
            managementApi.updateCommunicationType(this.beacon.Id, value);
        }
    }

    public async handleDeleteParameter(beaconParameterId: number) {
        if (this.beacon) {
            await managementApi.deleteBeaconParameter(this.beacon.Id, beaconParameterId);
            this.loadParameters();
        }
    }

    private handleNewParameterAdded() {
        this.addParamterModalIsOpen = false;
        this.loadParameters();
    }

    public render() {
        if (!this.beacon || !this.parameters) {
            return;
        }

        return (
            <div class="d-flex flex-column">
                <breadcrumb crumbs={this.crumbs} />
                <div class="scroll h-100">
                    <div class="container-fluid">
                        <div class="align-items-center d-flex flex-column row">
                            <panel class="col-lg-12 col-xl-6 text-left mt-3">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="font-weight-medium col-md-12 col-lg-6 d-flex flex-column">
                                            {this.$t("default.preferredCommunicationType")}
                                            <div class="text-muted d-flex font-size-s align-items-center">
                                                <div class="mr-2">{this.$t("default.currentCommunicationType")}</div>
                                                {this.beacon.LastCommunicationType !== null ? (
                                                    <div class="font-weight-bold">
                                                        {this.beacon.LastCommunicationType === CommunicationType.GSM
                                                            ? "GSM"
                                                            : "IR"}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-lg-end col-md-12 col-lg-6">
                                            <el-radio-group
                                                size="small"
                                                onChange={this.changeCommunicationType}
                                                vModel={this.communicationType}
                                            >
                                                <el-radio-button label={CommunicationType.Iridium}>
                                                    Iridium
                                                </el-radio-button>
                                                <el-radio-button label={CommunicationType.GSM}>GSM</el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </panel>
                            {this.user.CfgCanUseConfigTool && this.addParamterModalIsOpen && (
                                <add-parameter-modal
                                    beaconId={this.beacon.Id}
                                    onAdded={this.handleNewParameterAdded}
                                    visible={this.addParamterModalIsOpen}
                                    onClose={this.toggleAddParameterModal}
                                ></add-parameter-modal>
                            )}
                            {this.user.CfgCanUseConfigTool && (
                                <div class="col-lg-12 col-xl-6 text-left">
                                    <el-button
                                        onClick={this.toggleAddParameterModal}
                                        class="mb-3 p-3 flex-shrink-0 load-more-button w-100"
                                        type="text"
                                    >
                                        {this.$t("default.addParameter")}
                                    </el-button>
                                </div>
                            )}
                            {this.parameters.map(parameter => {
                                switch (parameter.ClientSpecification) {
                                    case "ModbusConfiguration":
                                        return (
                                            <modbus-configuration-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );
                                    case "ModbusCollectModeConfiguration":
                                        return (
                                            <modbus-collect-mode-configuration-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );
                                    case "ChannelsConfig":
                                        return (
                                            <channels-config-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );
                                    case "StatusConfiguration":
                                        return (
                                            <status-configuration-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );
                                    case "CollectModeConfiguration":
                                        return (
                                            <collect-mode-configuration-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );
                                    case "ExchangeModeConfiguration":
                                        return (
                                            <exchange-mode-configuration-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );
                                    case "NMEAModeConfig":
                                        return (
                                            <nmea-mode-configuration-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );

                                    case "SSASOptionalString":
                                        return (
                                            <ssas-config-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            ></ssas-config-template>
                                        );
                                    case "LRITConfiguration":
                                        return (
                                            <lrit-config-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            ></lrit-config-template>
                                        );
                                    case "LRITEnable":
                                        return (
                                            <lrit-enable-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            ></lrit-enable-template>
                                        );
                                    case "LRITPeriod":
                                        return (
                                            <lrit-period-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            ></lrit-period-template>
                                        );
                                    case "SSASDisableAlarm":
                                        return (
                                            <ssas-disable-alarm-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            ></ssas-disable-alarm-template>
                                        );
                                    case "CustomCommandTemplate":
                                        return (
                                            <custom-command-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            ></custom-command-template>
                                        );
                                    case "MaximumSendingTime":
                                    case "DataSendingPeriod":
                                    case "DataReceivingPeriod":
                                    case "CoordinateReceivingPeriod":
                                    case "GPSValidSessionTime":
                                    case "GPSPredictiveStartTime":
                                    case "OperationMode":
                                    case "IntermediateCoordinates":
                                    case "CollectAndSendData":
                                    case "DataChannel":
                                    case "Compression":
                                    case "AccelerationLevel":
                                    case "SimSelection":
                                    case "Reboot":
                                    case "AdaptiveMode":
                                    case "ForcedMode":
                                    case "BatteryLife":
                                    case "InternalSensors":
                                    case "CurrentCoordinates":
                                    case "FirmwareVersion":
                                    case "ClearArchives":
                                    case "ProtocolVersion":
                                    case "FactoryReset":
                                    case "OnlineModeCollectPeriod":
                                    case "OnlineModeSendPeriod":
                                    case "ModbusPeriod":
                                    case "Poll":
                                        return (
                                            <number-parameter-template
                                                onSave={this.saveParameter}
                                                onDelete={this.handleDeleteParameter}
                                                parameter={parameter}
                                            />
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get crumbs() {
        if (this.beacon) {
            const path1 = {
                label: this.$t("default.beacons"),
                name: "beacons"
            };
            const path2 = {
                label: this.beacon.Name,
                name: "beacon",
                params: { id: this.beacon.Id }
            };
            const path3 = {
                label: this.$t("remotecontrol.remotecontrol"),
                name: this.$route.name,
                params: { id: this.beacon.Id }
            };
            return [path1, path2, path3];
        }
        return [];
    }

    public async loadParameters() {
        this.loading = true;
        try {
            if (this.beacon) {
                this.parameters = await managementApi.loadParameters(this.beacon.Id);
            }
        } finally {
            this.loading = false;
        }
    }
    public async saveParameter(parameterId: number, value: string) {
        this.loading = true;
        try {
            if (this.beacon) {
                let parameter = await managementApi.updateBeaconParameter(this.beacon.Id, parameterId, value);
                let id = this.parameters.findIndex(x => x.Id === parameter.Id);
                Vue.set(this.parameters, id, parameter);
            }
        } finally {
            this.loading = false;
        }
    }

    public async created() {
        const beacon = await managementApi.loadBeacon(Number.parseInt(this.$route.params.id));
        this.beacon = beacon;
        this.communicationType = beacon.PreferredCommunicationType;
        this.loadParameters();
    }
}
