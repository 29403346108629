import TransportCardFields from "./TransportCardFields";
import Breadcrumb from "@/components/Menu/Breadcrumb";
import managementAPI from "@/api/management";
import regexHolder from "@/const/string-validation-regex";
import AsyncValidator from "async-validator";
import Panel from "@/components/Containers/Panel";

import { Component, Mixins } from "vue-property-decorator";
import messages from "@/components/Mixins/messages";
import cloneDeep from "lodash/cloneDeep";

@Component({
    components: {
        Breadcrumb,
        TransportCardFields,
        Panel
    }
})
export default class BeaconCard extends Mixins(messages) {
    beaconCard: any = null;
    beacon: any = null;
    form: any = null;
    modal: boolean = false;
    loading: any = (this.$loading as any).service();
    working: boolean = false;

    get crumbs() {
        let path1 = {
            label: this.$t("default.beacons"),
            name: "beacons"
        };
        let path2 = {
            label: this.beacon.Name,
            name: "beacon",
            params: { id: this.beacon.Id }
        };
        let path3 = {
            label: this.$t("beaconcard.objectcard"),
            name: this.$route.name,
            params: { id: this.beacon.Id }
        };
        return [path1, path2, path3];
    }

    async load() {
        try {
            this.beaconCard = await managementAPI.loadBeaconCard(Number.parseInt(this.$route.params.id));
            this.beacon = await managementAPI.loadBeacon(Number.parseInt(this.$route.params.id));

            this.setBeaconCard();
        } catch (error) {
            console.log(error);
        } finally {
            this.loading.close();
        }
    }

    setBeaconCard() {
        if (this.beaconCard.TransportCard) {
            this.form = JSON.parse(this.beaconCard.TransportCard);
        } else {
            this.form = [];
        }
    }

    save() {
        try {
            let descriptor = {
                label: [
                    {
                        validator(rule, value, callback, source, options) {
                            var errors: any = [];
                            for (let i = 0; i < value.length; i++) {
                                let field = value[i];
                                if (!regexHolder["transport_card_fields"].value.test(field)) {
                                    errors.push("Label - " + field + " is unvalid");
                                }
                            }
                            callback(errors);
                        }
                    }
                ]
            };
            var validator = new AsyncValidator(descriptor);
            let fields = this.form.map(value => {
                return value.value;
            });
            validator.validate({ label: fields }, (errors, fields) => {
                if (errors) {
                    this.$message({
                        message: this.$t("default.invalid").toString(),
                        showClose: true,
                        offset: 70,
                        iconClass: "fad fa-exclamation-circle fa-lg mr-3 color-white",
                        customClass: "el-message--error"
                    });
                    throw errors;
                } else {
                    this.successfullSave();
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    async successfullSave() {
        this.working = true;
        try {
            this.beaconCard = await managementAPI.updateBeaconCard(this.beacon.Id, {
                Id: this.beacon.Id,
                TransportCard: JSON.stringify(this.form)
            });
            this.setBeaconCard();
            this.operationSuccess();
        } catch (error) {
            this.operationFailed();
        } finally {
            this.working = false;
        }
    }

    discard() {
        if (this.beaconCard.TransportCard) {
            this.form = JSON.parse(this.beaconCard.TransportCard);
        } else {
            this.form = [];
        }
    }

    handleUpdateFields(data) {
        this.modal = false;
        this.form = cloneDeep(data);
    }

    handleDelete(index) {
        this.form.splice(index, 1);
    }

    handleAdd() {
        this.form.push({ label: "", value: "" });
    }

    created() {
        this.load();
    }

    render() {
        if (!this.beacon) {
            return null;
        }
        return (
            <div class="d-flex flex-column">
                <breadcrumb crumbs={this.crumbs} />
                <div class="scroll h-100">
                    <div class="container-fluid">
                        <div class="row justify-content-md-center">
                            <el-form
                                size="small"
                                class="col-lg-8 col-xl-6 col-md-12 text-left py-3"
                                label-position="left"
                                ref="transportForm"
                            >
                                <panel header={this.$t("beaconcard.objectcard")}>
                                    {this.form.length > 0 ? (
                                        this.form.map((item, index) => (
                                            <div class="d-flex align-items-start" key={index}>
                                                <el-form-item class="mr-3 w-25">
                                                    <el-input maxlength={100} vModel={item.label} />
                                                </el-form-item>
                                                <el-form-item class="mr-3 w-75">
                                                    <el-input maxlength={150} vModel={item.value} />
                                                </el-form-item>
                                                <el-button
                                                    size="small"
                                                    icon="fas fa-trash-alt"
                                                    onClick={() => this.handleDelete(index)}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <div class="d-flex justify-content-center text-muted font-size-s my-3">
                                            <p class="mb-0">{this.$t("default.nodata")}</p>
                                        </div>
                                    )}
                                    <el-button
                                        type="primary"
                                        class="w-100"
                                        size="small"
                                        icon="fas fa-plus"
                                        onClick={this.handleAdd}
                                    />
                                </panel>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="bottom-bar">
                    <div class="container-fluid">
                        <div class="row justify-content-md-center">
                            <div class="col-lg-8 col-xl-6 col-md-12 d-flex">
                                <el-button loading={this.working} onClick={this.save} size="small" type="primary">
                                    {this.$t("default.save")}
                                </el-button>
                                <el-button disabled={this.working} onClick={this.discard} size="small">
                                    {this.$t("default.reset")}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
