import { Vue, Component, Prop } from "vue-property-decorator";
import { IChannelInfo } from "@/contracts/IChannelInfo";
import { INotificationSettings } from "./INotificationSettingsForm";

@Component
export default class NotificationListItem extends Vue {
    @Prop()
    name!: string;

    @Prop()
    notificationSettings!: INotificationSettings;

    handleEdit() {
        this.$emit("edit", { ...this.notificationSettings });
    }

    handleDelete() {
        this.$emit("delete", this.notificationSettings.Id);
    }

    render() {
        return (
            <div class="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                <div>{this.name}</div>
                <div class="d-flex">
                    <el-button onClick={this.handleEdit} size="medium" icon="fas fa-sliders-h" />
                    <el-button onClick={this.handleDelete} size="medium" type="danger" icon="fas fa-trash-alt" />
                </div>
            </div>
        );
    }
}
