import { Component, Mixins } from "vue-property-decorator";

import config from "@/config";
import Panel from "@/components/Containers/Panel";
import RadioGroupEnums from "./radioGroupEnums";
import ParameterPanelHeader from "./ParameterPanelHeader";
import ParameterTemplateMixin from "./ParameterTemplateMixin";

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class ExchangeModeConfigurationTemplate extends Mixins(ParameterTemplateMixin) {
    timePickerOptions = {
        format: config.TIME_FORMAT,
        valueFormat: "HH:mm"
    };

    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    header={this.$t("exchangeModeConfigurationParameter.title")}
                    parameter={this.parameter}
                />
                <hr />
                <div class="d-flex flex-column">
                    <div class="w-100 text-left">{this.$t("exchangeModeConfigurationParameter.exchangeMode")}</div>
                    <el-radio-group vModel={this.value.Mode} class="w-100 d-flex flex-column align-items-start">
                        {RadioGroupEnums.ExchangeMode.map(enumValue => (
                            <el-radio class="mx-0 my-1" key={enumValue.value} label={enumValue.value}>
                                {this.$t(`enums.ExchangeMode.${enumValue.key}`)}
                            </el-radio>
                        ))}
                    </el-radio-group>
                    <div class="text-left w-100">{this.$t("exchangeModeConfigurationParameter.startTime")}</div>
                    <el-time-picker
                        size="medium"
                        clearable={false}
                        disabled={this.value.Mode !== 1}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.ExchangeStartTime}
                    />
                    <div class="w-100 text-left mt-3">{this.$t("exchangeModeConfigurationParameter.interval")}</div>
                    <el-input-number
                        disabled={this.value.Mode !== 1}
                        size="medium"
                        vModel={this.value.ExchangeInterval}
                    />
                    <div class="w-100 text-left mt-3">{this.$t("exchangeModeConfigurationParameter.schedule")}</div>
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time1}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time2}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time3}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time4}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time5}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time6}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time7}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time8}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time9}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time10}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time11}
                    />
                    <el-time-picker
                        size="medium"
                        disabled={this.value.Mode !== 2}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.Time12}
                    />
                </div>
            </panel>
        );
    }
}
