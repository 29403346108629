import { Component, Mixins } from "vue-property-decorator";
import Panel from "@/components/Containers/Panel";
import RadioGroupEnums from "./radioGroupEnums";
import ParameterPanelHeader from "./ParameterPanelHeader";
import ParameterTemplateMixin from "./ParameterTemplateMixin";

enum ValueType {
    Number = "Number",
    Enum = "Enum",
    String = "String",
    None = "None"
}

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class NumberParameterTemplate extends Mixins(ParameterTemplateMixin) {
    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    showResetButtons={this.parameter.AdditionalSettings.Value.Type !== ValueType.None}
                    header={this.$t(`parameters.${this.parameter.ClientSpecification}`)}
                    parameter={this.parameter}
                />
                {this.parameter.AdditionalSettings.Value.Type !== ValueType.None && (
                    <div>
                        <hr />
                        <div class="d-flex flex-column">
                            {this.parameter.AdditionalSettings.Value.Type === ValueType.Number ? (
                                <el-input-number size="medium" vModel={this.value.Value} />
                            ) : this.parameter.AdditionalSettings.Value.Type === ValueType.Enum ? (
                                <el-radio-group
                                    vModel={this.value.Value}
                                    class="w-100 d-flex flex-column align-items-start"
                                >
                                    {RadioGroupEnums[this.parameter.ClientSpecification].map(enumValue => (
                                        <el-radio class="mx-0 my-1" key={enumValue.value} label={enumValue.value}>
                                            {this.$t(`enums.${this.parameter.ClientSpecification}.${enumValue.key}`)}
                                        </el-radio>
                                    ))}
                                </el-radio-group>
                            ) : this.parameter.AdditionalSettings.Value.Type === ValueType.String ? (
                                <el-input size="medium" vModel={this.value.Value} />
                            ) : null}
                        </div>
                    </div>
                )}
            </panel>
        );
    }
}
