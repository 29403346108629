import managementAPI, { IParameterCreateModel } from "@/api/management";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import ParameterCard from "./ParameterCard";
import "./AddParameterModal.scss";

@Component({
    components: {
        ParameterCard
    }
})
export default class AddParameterModal extends Vue {
    @Prop()
    visible!: boolean;

    @Prop()
    beaconId!: number;

    private parameters: IParameterCreateModel[] = [];
    private selectedParameterId: number | null = null;

    @State
    user!: IUser;

    private handleParameterSelect(id: number) {
        this.selectedParameterId = id;
    }

    private async handleAddParameter() {
        if (this.selectedParameterId) {
            await managementAPI.createBeaconParameter(this.beaconId, this.selectedParameterId);
            this.load();
            this.$emit("added");
        }
    }

    private async load() {
        this.parameters = await managementAPI.loadParameterCreateModels(this.beaconId);
    }

    public mounted() {
        this.load();
    }

    public render() {
        return (
            <el-dialog
                class="add-parameter-modal"
                modal-append-to-body
                append-to-body
                close-on-click-modal={false}
                title="Добавление параметра"
                visible={this.visible}
                onClose={() => this.$emit("close")}
                width="600px"
            >
                <div style="background-color: #e1e8ed;" class="p-3 scroll flex-grow-1">
                    {this.parameters.map(parameter => (
                        <parameter-card
                            selected={this.selectedParameterId === parameter.Id}
                            parameter={parameter}
                            onSelect={this.handleParameterSelect}
                        ></parameter-card>
                    ))}
                </div>
                <div class="add-parameter-modal__footer">
                    <el-button
                        onClick={this.handleAddParameter}
                        size="small"
                        type="primary"
                        disabled={!this.selectedParameterId}
                    >
                        {this.$t("default.add")}
                    </el-button>
                </div>
            </el-dialog>
        );
    }
}
