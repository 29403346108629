import { Vue, Component, Prop } from "vue-property-decorator";
import "./Panel.scss";

@Component
export default class Panel extends Vue {
    @Prop()
    header!: string;

    @Prop()
    description!: string;

    render() {
        return (
            <div class="panel">
                {this.$slots.header || (this.header && <h4 class="panel__header">{this.header}</h4>)}
                {this.description && <h6 class="panel__description">{this.description}</h6>}
                {this.$slots.default}
            </div>
        );
    }
}
