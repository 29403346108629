export default [
    {
        key: "normal",
        value: 0
    },
    {
        key: "emergency",
        value: 1
    }
];
