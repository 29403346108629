import { Vue, Component, Mixins } from "vue-property-decorator";
import NotificationEditor from "./NotificationEditor";
import Breadcrumb from "@/components/Menu/Breadcrumb";
import managementAPI from "@/api/management";
import Panel from "@/components/Containers/Panel";
import IBeaconManagement from "@/contracts/IBeaconManagement";
import {
    INotificationSettings,
    NotificationType,
    NotificationMode,
    NotificationStatusType
} from "./INotificationSettingsForm";
import { notificationTypes } from "../const/notification-enums";
import head from "lodash/head";
import { IChannelInfo, ChannelType } from "@/contracts/IChannelInfo";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import NotificationListItem from "./NotificationListItem";
import cloneDeep from "lodash/cloneDeep";
import Messages from "@/components/Mixins/messages";

@Component({
    components: {
        NotificationListItem,
        NotificationEditor,
        Breadcrumb,
        Panel
    }
})
export default class NotificationsSettings extends Mixins(Messages) {
    private loading = (this.$loading as any).service();
    private notifications: INotificationSettings[] = [];
    private beacon: IBeaconManagement | null = null;
    private channels: IChannelInfo[] = [];
    private geoobjects: any[] = [];
    private currentNotification: INotificationSettings | null = null;
    private notificationEditor: boolean = false;

    @State
    user!: IUser;

    get locationInGeoobjectNotifications() {
        return this.notifications.filter(
            notificationSettings => notificationSettings.NotificationType === NotificationType.BeaconLocationInGeoobject
        );
    }

    get channelStateNotifications() {
        return this.notifications.filter(
            notificationSettings =>
                notificationSettings.NotificationType === NotificationType.BinaryChannelState ||
                notificationSettings.NotificationType === NotificationType.EnumChannelState ||
                notificationSettings.NotificationType === NotificationType.RealChannelState ||
                notificationSettings.NotificationType === NotificationType.RealChannelStateWithCustomThresholds
        );
    }

    get lastOnlineNotification() {
        return this.notifications.find(notification => notification.NotificationType === NotificationType.LastOnline);
    }
    
    get connectionStateNotification() {
        return this.notifications.find(
            notification => notification.NotificationType === NotificationType.BeaconConnectionState
        );
    }

    get bnwasStateNotification() {
        return this.notifications.find(
            notification => notification.NotificationType === NotificationType.BNWASEmergencyState
        );
    }

    get commandStateNotification() {
        return this.notifications.find(
            notification => notification.NotificationType === NotificationType.BeaconCommandState
        );
    }
    getDefaultNotificationSettings(notificationType: NotificationType, beaconId: number): INotificationSettings {
        return {
            Id: -1,
            Name: this.$t("enums.NotificationTypes." + NotificationType[notificationType]).toString(),
            NotificationType: notificationType,
            NotificationModes: [NotificationMode.App, NotificationMode.Email],
            StatusType: NotificationStatusType.Info,
            BeaconId: beaconId,
            ChannelId: null,
            GeoobjectId: null,
            Settings: notificationTypes[notificationType].settings,
            Emails: [this.$store.state.user.Email],
            IsDisabled: false
        };
    }

    async handleLastOnlineNotification(value: boolean) {
        if (!this.beacon) {
            return;
        }

        let notificationSettings: INotificationSettings = this.lastOnlineNotification
            ? { ...this.lastOnlineNotification }
            : this.getDefaultNotificationSettings(NotificationType.LastOnline, this.beacon.Id);

        await this.handleStandardNotification(value, notificationSettings);
    }

    async handleConnectionStateNotification(value: boolean) {
        if (!this.beacon) {
            return;
        }

        let notificationSettings: INotificationSettings = this.connectionStateNotification
            ? { ...this.connectionStateNotification }
            : this.getDefaultNotificationSettings(NotificationType.BeaconConnectionState, this.beacon.Id);

        await this.handleStandardNotification(value, notificationSettings);
    }

    async handleCommandStateNotification(value: boolean) {
        if (!this.beacon) {
            return;
        }

        let notificationSettings: INotificationSettings = this.commandStateNotification
            ? { ...this.commandStateNotification }
            : this.getDefaultNotificationSettings(NotificationType.BeaconCommandState, this.beacon.Id);

        await this.handleStandardNotification(value, notificationSettings);
    }

    async handleBnwasStateNotification(value: boolean) {
        if (!this.beacon) {
            return;
        }

        let notificationSettings: INotificationSettings = this.bnwasStateNotification
            ? { ...this.bnwasStateNotification }
            : this.getDefaultNotificationSettings(NotificationType.BNWASEmergencyState, this.beacon.Id);

        await this.handleStandardNotification(value, notificationSettings);
    }

    async handleStandardNotification(value: boolean, notificationSettings: INotificationSettings) {
        if (!this.beacon) {
            return;
        }

        let notificationSettingsCopy = cloneDeep(notificationSettings);

        if (!~notificationSettingsCopy.Id) {
            const newNotificationSettings = await managementAPI.createNotificationSettings(
                this.beacon.Id,
                notificationSettingsCopy
            );
            this.notifications.push(newNotificationSettings);
            notificationSettingsCopy.Id = newNotificationSettings.Id;
        }

        const notificationSettingsForm: INotificationSettings = { ...notificationSettingsCopy, IsDisabled: !value };
        let updatedNotification = await managementAPI.updateNotificationSettings(
            this.beacon.Id,
            notificationSettingsCopy.Id,
            notificationSettingsForm
        );

        const notificationId = this.notifications.findIndex(
            notification => notification.Id === notificationSettingsCopy.Id
        );

        if (~notificationId) {
            Vue.set(this.notifications, notificationId, updatedNotification);
        }
    }

    get crumbs() {
        const path1 = {
            label: this.$t("default.beacons"),
            name: "beacons"
        };
        const path2 = {
            label: this.beacon && this.beacon.Name,
            name: "beacon",
            params: { id: this.beacon && this.beacon.Id }
        };
        const path3 = {
            label: this.$t("notificationssettings.notificationssettings"),
            name: this.$route.name,
            params: { id: this.beacon && this.beacon.Id }
        };
        return [path1, path2, path3];
    }

    handleCreate(notificationType: NotificationType) {
        if (!this.beacon) {
            return;
        }

        let channelId: number | null = null;
        if (notificationType === NotificationType.RealChannelState) {
            const channel = this.channels.find(
                channel => channel.ChannelType === ChannelType.Binary || channel.ChannelType === ChannelType.Real
            );
            if (channel) {
                channelId = channel.Id;
                notificationType =
                    channel.ChannelType === ChannelType.Real
                        ? NotificationType.RealChannelState
                        : channel.ChannelType === ChannelType.Binary
                        ? NotificationType.BinaryChannelState
                        : NotificationType.None;
            }
        }

        let geoobjectId: number | null = null;
        if (notificationType === NotificationType.BeaconLocationInGeoobject) {
            const geoobject = head(this.geoobjects);
            if (geoobject) {
                geoobjectId = geoobject.Id;
            }
        }

        this.currentNotification = {
            Id: -1,
            Name: this.$t("enums.NotificationTypes." + NotificationType[notificationType]).toString(),
            NotificationType: notificationType,
            NotificationModes: [NotificationMode.App, NotificationMode.Email],
            StatusType: NotificationStatusType.Info,
            BeaconId: this.beacon.Id,
            ChannelId: channelId,
            GeoobjectId: geoobjectId,
            Settings: notificationTypes[notificationType].settings,
            Emails: [this.user.Email],
            IsDisabled: false
        };

        this.notificationEditor = true;
    }

    handleEdit(notificationSettings: INotificationSettings | undefined) {
        if (notificationSettings) {
            this.currentNotification = notificationSettings;
            this.notificationEditor = true;
        }
    }

    async handleDelete(id: number) {
        try {
            if (this.beacon) {
                await this.$confirm(this.$t("notificationssettings.deleteconfirm").toString(), "", {
                    confirmButtonText: this.$t("default.ok").toString(),
                    cancelButtonText: this.$t("default.cancel").toString(),
                    type: "warning",
                    center: true,
                    showClose: false
                });

                await managementAPI.deleteNotificationSettings(this.beacon.Id, id);

                this.notifications = this.notifications.filter(el => el.Id !== id);
                this.operationSuccess();
            }
        } catch {}
    }

    async handleSave(notificationId: number, notificationForm: INotificationSettings) {
        if (!this.beacon) {
            return;
        }

        if (~notificationId) {
            const updatedNotification = await managementAPI.updateNotificationSettings(
                this.beacon.Id,
                notificationId,
                notificationForm
            );

            this.notifications = this.notifications.map(notification =>
                notification.Id === updatedNotification.Id ? updatedNotification : notification
            );
        } else {
            const newNotification = await managementAPI.createNotificationSettings(this.beacon.Id, notificationForm);
            this.notifications.push(newNotification);
        }

        this.operationSuccess();
        this.notificationEditor = false;
    }

    findGeoobject(id: number) {
        return this.geoobjects.find(geobject => geobject.Id === id);
    }

    findChannel(id: number) {
        return this.channels.find(channel => channel.Id === id);
    }

    mounted() {
        this.loading = (this.$loading as any).service();
        const beaconId = Number.parseInt(this.$route.params.id);
        Promise.all([
            managementAPI.loadBeacon(beaconId),
            managementAPI.loadNotificationsSettings(beaconId),
            managementAPI.loadChannels(beaconId),
            managementAPI.loadGeoobjects()
        ])
            .then(([beacon, notifications, channels, geoobjects]) => {
                this.beacon = beacon;
                this.channels = channels;
                this.geoobjects = geoobjects;
                this.notifications = notifications;
            })
            .finally(() => {
                this.loading.close();
            });
    }

    render() {
        return (
            <div class="d-flex flex-column">
                {this.beacon && <breadcrumb crumbs={this.crumbs} />}
                <div class="text-left py-3 scroll h-100">
                    {this.notificationEditor && (
                        <div>
                            <el-dialog
                                modal-append-to-body={true}
                                append-to-body={true}
                                close-on-click-modal={false}
                                title={this.$t("notificationssettings.EditTitle")}
                                onClose={() => (this.notificationEditor = false)}
                                visible={this.notificationEditor}
                                width="500px"
                            >
                                <notification-editor
                                    beacon={this.beacon}
                                    notificationSettings={this.currentNotification}
                                    channels={this.channels.filter(channel => channel.ChannelType !== ChannelType.Enum)}
                                    geoobjects={this.geoobjects}
                                    onSave={this.handleSave}
                                    onClose={() => (this.notificationEditor = false)}
                                />
                            </el-dialog>
                        </div>
                    )}
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-12">
                                <panel header={this.$t("notificationssettings.StandardNotifications")}>
                                    <div class="d-flex justify-content-between mb-3">
                                        <div class="d-flex align-items-center">
                                            <el-switch
                                                class="mr-3"
                                                value={
                                                    this.connectionStateNotification &&
                                                    !this.connectionStateNotification.IsDisabled
                                                }
                                                onChange={this.handleConnectionStateNotification}
                                            />
                                            {this.$t("notificationssettings.ConnectionState")}
                                        </div>
                                        <el-button
                                            onClick={() => this.handleEdit(this.connectionStateNotification)}
                                            disabled={
                                                !this.connectionStateNotification ||
                                                this.connectionStateNotification.IsDisabled
                                            }
                                            size="medium"
                                            icon="fas fa-sliders-h"
                                        />
                                    </div>
                                    <div class="d-flex justify-content-between mb-3">
                                        <div class="d-flex align-items-center">
                                            <el-switch
                                                class="mr-3"
                                                value={
                                                    this.commandStateNotification &&
                                                    !this.commandStateNotification.IsDisabled
                                                }
                                                onChange={this.handleCommandStateNotification}
                                            />
                                            {this.$t("notificationssettings.CommandState")}
                                        </div>
                                        <el-button
                                            onClick={() => this.handleEdit(this.commandStateNotification)}
                                            disabled={
                                                !this.commandStateNotification ||
                                                this.commandStateNotification.IsDisabled
                                            }
                                            size="medium"
                                            icon="fas fa-sliders-h"
                                        />
                                    </div>
                                    <div class="d-flex justify-content-between mb-3">
                                        <div class="d-flex align-items-center">
                                            <el-switch
                                                class="mr-3"
                                                value={
                                                    this.lastOnlineNotification &&
                                                    !this.lastOnlineNotification.IsDisabled
                                                }
                                                onChange={this.handleLastOnlineNotification}
                                            />
                                            {this.$t("notificationssettings.LastOnline")}
                                        </div>
                                        <el-button
                                            onClick={() => this.handleEdit(this.lastOnlineNotification)}
                                            disabled={
                                                !this.lastOnlineNotification || this.lastOnlineNotification.IsDisabled
                                            }
                                            size="medium"
                                            icon="fas fa-sliders-h"
                                        />
                                    </div>
                                </panel>
                                <panel>
                                    <div
                                        slot="header"
                                        class="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2"
                                    >
                                        <h4 class="mb-0 panel__header">
                                            {this.$t("notificationssettings.GeoobjectsNotifications")}
                                        </h4>
                                        <el-button
                                            type="primary"
                                            onClick={() =>
                                                this.handleCreate(NotificationType.BeaconLocationInGeoobject)
                                            }
                                            size="medium"
                                            icon="fas fa-plus"
                                        />
                                    </div>
                                    {this.locationInGeoobjectNotifications.map(notificationSettings => {
                                        if (notificationSettings.GeoobjectId) {
                                            let geoobject = this.findGeoobject(notificationSettings.GeoobjectId);
                                            if (geoobject) {
                                                return (
                                                    <notification-list-item
                                                        name={geoobject.Name}
                                                        notificationSettings={notificationSettings}
                                                        onEdit={this.handleEdit}
                                                        onDelete={this.handleDelete}
                                                    />
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                    {this.locationInGeoobjectNotifications.length === 0 && (
                                        <div class="px-3 mt-3 d-flex align-items-center justify-content-center text-muted">
                                            {this.$t("default.noData")}
                                        </div>
                                    )}
                                </panel>
                                <panel>
                                    <div
                                        slot="header"
                                        class="d-flex justify-content-between align-items-center mb-2 pb-2 border-bottom"
                                    >
                                        <h4 class="mb-0 panel__header">
                                            {this.$t("notificationssettings.ChannelsNotifications")}
                                        </h4>
                                        <el-button
                                            type="primary"
                                            disabled={
                                                !this.channels.find(
                                                    channel =>
                                                        channel.ChannelType === ChannelType.Real ||
                                                        channel.ChannelType === ChannelType.Binary
                                                )
                                            }
                                            onClick={() => this.handleCreate(NotificationType.RealChannelState)}
                                            size="medium"
                                            icon="fas fa-plus"
                                        />
                                    </div>
                                    {this.channelStateNotifications.map(notificationSettings => {
                                        if (notificationSettings.ChannelId) {
                                            let channel = this.findChannel(notificationSettings.ChannelId);
                                            if (channel) {
                                                return (
                                                    <notification-list-item
                                                        name={channel.Name}
                                                        notificationSettings={notificationSettings}
                                                        onEdit={this.handleEdit}
                                                        onDelete={this.handleDelete}
                                                    />
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                    {this.channelStateNotifications.length === 0 && (
                                        <div class="px-3 mt-3 d-flex align-items-center justify-content-center text-muted">
                                            {this.$t("default.noData")}
                                        </div>
                                    )}
                                </panel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
