import { Component, Mixins } from "vue-property-decorator";

import config from "@/config";
import Panel from "@/components/Containers/Panel";

import ParameterPanelHeader from "./ParameterPanelHeader";
import ParameterTemplateMixin from "./ParameterTemplateMixin";

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class CollectModeConfigurationTemplate extends Mixins(ParameterTemplateMixin) {
    timePickerOptions = {
        format: config.TIME_FORMAT,
        valueFormat: "HH:mm"
    };

    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    header={this.$t("collectModeParameter.title")}
                    parameter={this.parameter}
                />
                <hr />
                <div class="d-flex flex-column">
                    <div class="w-100 text-left mt-3">{this.$t("collectModeParameter.collectStartTime")}</div>
                    <el-time-picker
                        size="medium"
                        clearable={false}
                        class="mb-3"
                        {...{ attrs: this.timePickerOptions }}
                        vModel={this.value.CollectStartTime}
                    />
                    <div class="w-100 text-left mt-3">{this.$t("collectModeParameter.collectInterval")}</div>
                    <el-input-number size="medium" vModel={this.value.CollectInterval} />
                </div>
            </panel>
        );
    }
}
