import { Vue, Component, Prop } from "vue-property-decorator";
import IParameter from "@/contracts/IParameter";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class ParameterTemplateMixin extends Vue {
    @Prop()
    parameter!: IParameter;

    value = cloneDeep(this.parameter.ValueForTransmit || this.parameter.DefaultValue);

    resetToDefault() {
        this.value = cloneDeep(this.parameter.DefaultValue);
    }

    reset() {
        this.value = cloneDeep(this.parameter.ValueForTransmit || this.parameter.DefaultValue);
    }

    delete() {
        this.$emit("delete", this.parameter.Id);
    }

    save() {
        const value = JSON.stringify(this.value);
        this.$emit("save", this.parameter.Id, value);
    }
}
