import managementAPI from "@/api/management";
import { mask } from "vue-the-mask";
import regexHolder from "@/const/string-validation-regex";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import pictures from "@/const/beacon-pictures";
import colors from "@/const/colors";
import Message from "@/components/Mixins/messages";

import ImagePicker from "@/components/Select/ImagePicker.vue";
import ColorPicker from "@/components/Select/ColorPicker.vue";

@Component({
    components: {
        ImagePicker,
        ColorPicker
    },
    directives: {
        mask
    }
})
export default class ActivationModal extends Mixins(Message) {
    @Prop()
    visible!: boolean;

    private working: boolean = false;
    private form: any = {
        BeaconName: "",
        Imei: "",
        ActivationCode: "",
        IconPath: "Car0",
        UserInterfaceSettings: {
            Color: "#0b52bc",
            TrackLineWidth: 5
        }
    };

    @State
    user!: IUser;

    private async handleActivate() {
        try {
            await (this.$refs["activateForm"] as any).validate();

            this.working = true;
            this.form.ActivationCode = this.form.ActivationCode.toUpperCase();

            await managementAPI.activateBeacon(this.form);
            this.success();
        } catch (error) {
            error.response && this.handleError(error);
        } finally {
            this.working = false;
        }
    }

    private success() {
        this.operationSuccess();
        this.$emit("activated");
    }

    private handleError(error) {
        if (error.response.status === 404) {
            this.$alert(
                this.$t("beaconactivation.faildescription").toString(),
                this.$t("beaconactivation.failtitle").toString(),
                {
                    type: "error",
                    confirmButtonText: "ОК"
                }
            );
        } else if (error.response.status === 403) {
            this.$alert(
                this.$t("beaconactivation.notreadydescription").toString(),
                this.$t("beaconactivation.notready").toString(),
                {
                    type: "error",
                    confirmButtonText: "ОК"
                }
            );
        }
    }

    private rules() {
        return {
            Imei: [
                {
                    required: true,
                    message: this.$t("beaconactivation.emptyvalidation"),
                    trigger: "blur"
                },
                {
                    min: 15,
                    max: 15,
                    message: this.$t("beaconactivation.imeivalidation", {
                        count: 15
                    }),
                    trigger: "blur"
                }
            ],
            ActivationCode: [
                {
                    required: true,
                    message: this.$t("beaconactivation.emptyvalidation"),
                    trigger: "blur"
                },
                {
                    min: 23,
                    max: 23,
                    message: this.$t("beaconactivation.activationcodevalidation", { count: 23 }),
                    trigger: "blur"
                }
            ],
            BeaconName: [
                {
                    pattern: regexHolder["settings_name"].value,
                    message: this.$t("default.invalid"),
                    trigger: "blur"
                }
            ]
        };
    }

    public render() {
        return (
            <el-dialog
                modal-append-to-body
                append-to-body
                close-on-click-modal={false}
                title={this.$t("beaconactivation.title")}
                visible={this.visible}
                onClose={() => this.$emit("close")}
                width="600px"
            >
                <el-form
                    size="small"
                    {...{ attrs: { model: this.form } }}
                    rules={this.rules()}
                    label-position="top"
                    ref="activateForm"
                >
                    <el-form-item label="IMEI" prop="Imei">
                        <el-input vModel={this.form.Imei} placeholder="XXXXXXXXXXXXXXX" vMask={"###############"} />
                    </el-form-item>
                    <el-form-item label={this.$t("beaconactivation.activationcode")} prop="ActivationCode">
                        <el-input
                            placeholder="XXXXX-XXXXX-XXXXX-XXXXX"
                            vMask={"XXXXX-XXXXX-XXXXX-XXXXX"}
                            vModel={this.form.ActivationCode}
                        />
                    </el-form-item>
                    <el-form-item label={this.$t("default.name")} prop="BeaconName">
                        <el-input vModel={this.form.BeaconName} maxlength={250} />
                    </el-form-item>
                    {this.form.UserInterfaceSettings && (
                        <div>
                            <el-form-item style="flex: 1;" label={this.$t("default.icon")}>
                                <image-picker
                                    items={pictures}
                                    vModel={this.form.IconPath}
                                    background-color={this.form.UserInterfaceSettings.Color}
                                />
                            </el-form-item>
                            <el-form-item>
                                <div class="d-flex flex-column">
                                    <div class="font-weight-medium">{this.$t("default.color")}</div>
                                    <color-picker items={colors} vModel={this.form.UserInterfaceSettings.Color} />
                                </div>
                            </el-form-item>
                        </div>
                    )}
                    <el-form-item>
                        <el-button
                            loading={this.working}
                            onClick={this.handleActivate}
                            disabled={!this.user.CanRegisterBeacons}
                            type="primary"
                        >
                            {this.$t("default.activate")}
                        </el-button>
                        <el-button disabled={this.working} onClick={() => this.$emit("update:visible", false)}>
                            {this.$t("default.cancel")}
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        );
    }
}
