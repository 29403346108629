import managementAPI from "@/api/management";
import { findBeacons } from "@/search";

import IconBase from "@/components/Icons/IconBase.vue";
import ActivationModal from "./ActivationModal";
import Panel from "@/components/Containers/Panel";
import { Component, Vue } from "vue-property-decorator";

import "./BeaconsManagement.scss";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";

@Component({
    components: {
        ActivationModal,
        IconBase,
        Panel
    }
})
export default class BeaconsManagement extends Vue {
    private modal: boolean = false;
    private filter: string = "";
    private beacons: any[] = [];

    @State
    user!: IUser;

    private get filteredBeacons() {
        if (this.filter) {
            return findBeacons(this.beacons, this.filter);
        }
        return this.beacons;
    }

    public activated() {
        this.modal = false;
        this.loadData();
    }

    private handleEdit(beacon) {
        this.$router.push({
            name: "beacon",
            params: { id: beacon.Id }
        });
    }

    private async loadData() {
        this.beacons = await managementAPI.loadBeacons();
    }

    public created() {
        this.loadData();
    }

    public render() {
        return (
            <div class="beacons-management scroll">
                {this.modal && (
                    <activation-modal
                        onActivated={() => {
                            this.activated();
                        }}
                        onClose={() => {
                            this.modal = false;
                        }}
                        visible={this.modal}
                    />
                )}
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="text-left col-xl-6 col-md-12">
                            <panel>
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-4 col-12 d-flex align-items-center mb-3">
                                            <h4 class="page-header mb-0">{this.$t("default.beacons")}</h4>
                                        </div>
                                        <div class="d-flex col-md-10 col-sm-8 col-12 mb-3">
                                            <el-input
                                                vModel={this.filter}
                                                clearable
                                                size="small"
                                                class="mr-3"
                                                prefix-icon="el-icon-search"
                                                placeholder={this.$t("default.search")}
                                            />
                                            {this.user.FdIntegration && (
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    onClick={() => {
                                                        this.$router.push({
                                                            name: "integrations"
                                                        });
                                                    }}
                                                >
                                                    {this.$t("default.integrations")}
                                                </el-button>
                                            )}
                                            <el-button
                                                size="small"
                                                type="primary"
                                                onClick={() => {
                                                    this.modal = true;
                                                }}
                                            >
                                                {this.$t("default.activate")}
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                                <el-table
                                    fit
                                    data={this.filteredBeacons}
                                    show-header={false}
                                    empty-text={this.$t("default.nodata")}
                                >
                                    <el-table-column
                                        min-width="150"
                                        label={this.$t("default.name")}
                                        fixed="left"
                                        {...{
                                            scopedSlots: {
                                                default: (scope: { row: any }) => {
                                                    return (
                                                        <div
                                                            class="d-flex align-items-center"
                                                            onClick={() => {
                                                                this.handleEdit(scope.row);
                                                            }}
                                                        >
                                                            <div class="d-flex align-items-center justify-content-center mr-2">
                                                                <div
                                                                    style={{
                                                                        backgroundColor: `${scope.row.UserInterfaceSettings.Color}22`
                                                                    }}
                                                                    class="rounded-circle"
                                                                >
                                                                    <icon-base
                                                                        icon-height={24}
                                                                        icon-width={24}
                                                                        icon-name={scope.row.IconPath}
                                                                        icon-color={
                                                                            scope.row.UserInterfaceSettings.Color
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="d-flex align-items-center text-truncate">
                                                                {scope.row.Name}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                    <el-table-column
                                        label={this.$t("default.actions")}
                                        align="right"
                                        {...{
                                            scopedSlots: {
                                                default: (scope: { row: any }) => {
                                                    return (
                                                        <div class="d-flex align-items-center justify-content-end">
                                                            <el-button
                                                                icon="fa-lg fad fa-sliders-h"
                                                                size="small"
                                                                onClick={() => {
                                                                    this.handleEdit(scope.row);
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                </el-table>
                            </panel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
