import { Component, Mixins } from "vue-property-decorator";
import Panel from "@/components/Containers/Panel";
import ParameterPanelHeader from "./ParameterPanelHeader";
import ParameterTemplateMixin from "./ParameterTemplateMixin";

@Component({
    components: {
        Panel,
        ParameterPanelHeader
    }
})
export default class ModbusConfigurationTemplate extends Mixins(ParameterTemplateMixin) {
    addModbusSensor() {
        this.value.Sensors.push({ Id: 0, RegisterNumber: 0 });
    }

    deleteModbusSensor(index) {
        this.value.Sensors.splice(index, 1);
    }

    render() {
        return (
            <panel class="col-lg-12 col-xl-6 text-left">
                <parameter-panel-header
                    onSave={this.save}
                    onDelete={this.delete}
                    onResetToDefault={this.resetToDefault}
                    onReset={this.reset}
                    showResetButtons={false}
                    header={this.$t(`parameters.${this.parameter.ClientSpecification}`)}
                    parameter={this.parameter}
                />
                <div>
                    <hr />
                    <div class="d-flex flex-column">
                        {this.value.Sensors.map(x => (
                            <div class="d-flex mb-3">
                                <el-input-number class="mr-3" size="medium" vModel={x.Id} />
                                <el-input class="mr-3" size="medium" vModel={x.RegisterNumber} />
                                <el-button
                                    onClick={this.deleteModbusSensor}
                                    size="small"
                                    type="danger"
                                    disabled={this.value.Sensors.length === 1}
                                >
                                    {this.$t("default.delete")}
                                </el-button>
                            </div>
                        ))}
                    </div>
                    <el-button
                        onClick={this.addModbusSensor}
                        size="small"
                        type="primary"
                        disabled={this.value.Sensors.length === 50}
                    >
                        {this.$t("default.add")}
                    </el-button>
                </div>
            </panel>
        );
    }
}
