export default [
    "Car0",
    "Car1",
    "Car2",
    "Car3",
    "Car4",
    "Car5",
    "Car6",
    "Car7",
    "Car8",
    "Car9",
    "Car10",
    "Air0",
    "Air1",
    "Air2",
    "Air3",
    "Air4",
    "Air5",
    "Air6",
    "Air7",
    "Air8",
    "Air9",
    "Air10",
    "Air11",
    "Air12",
    "Cargo0",
    "Cargo1",
    "Cargo2",
    "Cargo3",
    "Cargo4",
    "Cargo5",
    "Cargo6",
    "Cargo7",
    "Cargo8",
    "Cargo9",
    "Container0",
    "Rail0",
    "Rail1",
    // "Rail2",
    "Rail3",
    "Rail4",
    "Rail5",
    "Rail6",
    "Rail7",
    "Sea0",
    "Sea1",
    "Sea2",
    "Sea3",
    "Sea4",
    "Sea5",
    "Sea6",
    "Sea7",
    "Sea8",
    "Sea9",
    "Sea10",
    "Special0",
    "Special1",
    "Special2",
    "Special3",
    "Special4",
    "Special5",
    "Special6",
    "Special7",
    "Special8",
    "Special9",
    "Special10",
    "Special11"
];
