//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SettingsCard",
    props: {
        item: Object,
        user: Object,
        beacon: Object
    },
    computed: {
        cardDisabled() {
            if (this.item.integration) {
                return !this.user.FdIntegration;
            }
            return this.item.permission && (!this.user[this.item.permission] || !this.beacon[this.item.permission]);
        }
    }
};
